import React, { useState } from 'react'
import './login.css'
import { useParams } from 'react-router-dom'
import Success from './success'
import Form from './form'

export default function Index() {

    const { token } = useParams()
    const [isPasswordChanged, setIsPasswordChanged] = useState(false)

    return (
        isPasswordChanged ?
            <Success /> :
            <Form
                token={token}
                setIsPasswordChanged={setIsPasswordChanged}
            />
    )
}
