import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  let location = useLocation();

  useEffect(() => {
    let hashElement = document.getElementById((location.hash).slice(1));
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: "smooth",
        // block: "end",
        inline: "nearest",
      });
    }
  });

  return null;
};

export default ScrollToHashElement;
