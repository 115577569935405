import './login.css'
import { useExternalFile } from 'useExternalFile';
import LogInForm from './logInForm';
import SignupForm from './signupForm';
import { useState, useContext, useEffect } from 'react';
import { ProfileContext } from 'context';
import { useNavigate } from 'react-router-dom';

export default function Index(props) {

    useExternalFile("/assets/js/login.js")

    const navigate = useNavigate()

    const [confirmationEmailSent, setConfirmationEmailSent] = useState(false)
    const { profileData, token } = useContext(ProfileContext)

    useEffect(() => {
        if (profileData && token) {
            navigate('/myApplications')
        }
    }, [profileData, navigate, token])

    return (
        <>
            {
                confirmationEmailSent ?
                    <h6 className='signup-email-notification'>
                        Thank you for signing up! Please check your inbox for a confirmation email and follow the instructions to
                        complete your registration. For any issues, contact our support team."
                    </h6> : null
            }
            <div className={`login container ${confirmationEmailSent ? 'emailSent' : ''}`}>
                <div className="overlay" id="overlay">
                    <div className="sign-in" id="sign-in">
                        <h1>Welcome Back!</h1>
                        <p>To keep connected with us please login with your personal info</p>
                        <button className="switch-button" id="slide-right-button">Sign In</button>
                    </div>
                    <div className="sign-up" id="sign-up">
                        <h1>Hello, Friend!</h1>
                        <p>Enter your personal details and start a journey with us</p>
                        <button className="switch-button" id="slide-left-button">Sign Up</button>
                    </div>
                </div>
                <div className="form">
                    <div className="sign-in" id="sign-in-info">
                        <h1>Sign In</h1>
                        <LogInForm />
                    </div>
                    <SignupForm setConfirmationEmailSent={setConfirmationEmailSent} />
                </div>
            </div>


        </>
    )
}
