import React, { useEffect, useState } from 'react'
import CreateUploadFile from './create-upload-File'
import ViewFile from './viewFile'

export default function Index({ application, question, setFormValues, setResumeBuffer, setResumeImgBuffer, setCoverLetterBuffer,
    fileActionResume, setFileActionResume, fileActionCoverLetter, setFileActionCoverLetter, navTab, formValues }) {

    const [fileAction, setFileAction] = useState()

    useEffect(() => {
        if (!application) {
            if (question.id === "resume") {
                setFileAction(fileActionResume)
            } else if (question.id === "coverLetter") {
                setFileAction(fileActionCoverLetter)
            } else if (question.id === "resumeImage") {
                setFileAction("upload-resumeImage")
            }
        } else {
            if (question.id === "resume") {
                if (application.createResume.shouldCreate) {
                    setFileAction(`create-${question.id}`)
                } else {
                    setFileAction(`upload-${question.id}`)
                }
            } else if (question.id === "coverLetter") {
                if (application.createCoverLetter.shouldCreate) {
                    setFileAction(`create-${question.id}`)
                } else if (application.createCoverLetter.didUserUpload)  {
                    setFileAction(`upload-${question.id}`)
                }
            } else if (question.id === "resumeImage") {
                setFileAction("upload-resumeImage")
            }
        }
    }, [application, question.id, fileActionCoverLetter, fileActionResume])

    useEffect(() => {
        if (fileAction !== `upload-${question.id}`) {
            reset()
        }
    }, [fileAction])

    function handleFileChange(event) {
        const file = event.target.files[0];
        // console.log(file)
        const maxFileSize = 3 * 1024 * 1024; // 3 MB in bytes

        if (file.size > maxFileSize) {
            alert(`file size must be at most 3 MB.`, 'warning', 5000, 'top-center');
            return;
        }

        setFormValues(prevValues => ({
            ...prevValues,
            [navTab]: {
                ...formValues[navTab],
                [event.target.name]: file.name
            }
        }));
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

            if (question.id === "resume") {
                setResumeBuffer(reader.result)
            } else if (question.id === "coverLetter") {
                setCoverLetterBuffer(reader.result)
            } else if (question.id === "resumeImage") {
                setResumeImgBuffer(reader.result)
            }
        };
    }

    const reset = () => {
        setFormValues(prevValues => ({
            ...prevValues,
            [navTab]: {
                ...formValues[navTab],
                [question.id]: "",
            }
        }));

        if (question.id === "resume") {
            setResumeBuffer(null)
        } else if (question.id === "coverLetter") {
            setCoverLetterBuffer(null)
        } else if (question.id === "resumeImage") {
            setResumeImgBuffer(null)
        }
    }

    return (
        <>
            {
                question.id !== "resumeImage" ?
                    <>
                        <CreateUploadFile
                            question={question}
                            fileAction={fileAction}
                            setFileActionResume={setFileActionResume}
                            setFileActionCoverLetter={setFileActionCoverLetter}
                            isDisabled={application}
                        />
                    </> : null
            }

            {
                application ?
                    <div className='col-md-5'>
                        <label className="form-label" style={question.id !== "resumeImage" ? { visibility: "hidden" } : { color: "white" }}>
                            {
                                question.id !== "resumeImage" ? 'label' : `upload-${question.id}`
                            }
                        </label>
                        <ViewFile question={question} />
                    </div>
                    :

                    <>
                        <div className='col-md-3'>
                            <label htmlFor={fileAction} className="form-label" style={{ color: "white" }}>{`upload-${question.id}`}
                                {
                                    (question.id !== "resumeImage" && fileAction === `upload-${question.id}`) ? <span style={{ color: "red" }}> *</span> : null
                                }
                            </label>
                            <label htmlFor={fileAction}
                                name={question.id}
                                className={`form-control 
                ${question.id === "resumeImage" ?
                                        (fileActionResume === "create-resume" ? '' : 'disabledInput') :
                                        fileAction !== `upload-${question.id}` ? 'disabledInput' :
                                            ''}
                `}
                                style={{ fontSize: "1rem" }}>{formValues[navTab]?.[question.id] || "No file choosen"}</label>
                            <input
                                style={{ display: "none" }}
                                id={fileAction}
                                type="file"
                                name={question.id}
                                onChange={handleFileChange}
                                accept={question.accept}
                                disabled={
                                    question.id === "resumeImage" ? (fileActionResume === "create-resume" ? false : true) :
                                        fileAction !== `upload-${question.id}` ? true :
                                            false
                                }
                            />
                        </div>

                        <div className='col-md-2 resetBtn'>
                            <label className="form-label" style={{ visibility: "hidden" }}>label</label>
                            {
                                formValues[navTab]?.[question.id] ?
                                    <button
                                        className="btn btn-started"
                                        type='button'
                                        onClick={reset}
                                    >
                                        reset
                                    </button> : null
                            }
                        </div>

                    </>
            }

        </>
    )
}
