import { UpdatesContext } from 'context'
import React, { useContext } from 'react'

export default function Dropdown() {

    const { filters, setFilters } = useContext(UpdatesContext)

    const handleSort = async (sortDirection) => {
        setFilters(prev => ({
            ...prev,
            sortDirection
        }))
    }


    return (
        <>
            <div className="main dropdown">
                <p className='sortBy'>Sort by </p>
                <button
                    className="btn btn-white dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {
                        filters?.sortDirection === 'asc' ? 'Old Updates' : 'Latest Updates'
                    }
                </button>
                <ul className="dropdown-menu">
                    <li><button className="dropdown-item" type="button" onClick={() => handleSort('desc')}>Latest Updates</button></li>
                    <li><button className="dropdown-item" type="button" onClick={() => handleSort('asc')}>Old Updates</button></li>
                </ul>
            </div>
            <br />
        </>
    )
}
