import { useState, useEffect } from "react"
import ViewPDF from "MyProfile/viewPDF"
import Spinner from "Spinner"
import { CreateUserFile } from "api/employee"

export default function File({ type, applicantEmail, itemCount, applicationId, isCreated }) {

    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [uploadFile, setUploadFile] = useState()
    const [showPDF, setShowPDF] = useState(false)
    const [isUploadedSuccessfully, setIsUploadedSuccessfully] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const maxFileSize = 3 * 1024 * 1024; // 3 MB in bytes
        const src = URL.createObjectURL(file);
        file.src = src
        // console.log(file)

        if (file.size > maxFileSize) {
            alert(`file size must be at most 3 MB.`, 'warning', 5000, 'top-center');
            return;
        }

        setIsFileUploaded(true)

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(`file buffer is: ${reader.result}`);
            if (reader.result) {
                file.src = reader.result
            }
        };

        setUploadFile(file)
    }

    const cancelUpload = async () => {
        setIsFileUploaded(false)
        setUploadFile(null)
    }

    const uploadBucketFile = async () => {
        try {

            setShowSpinner(true)
            const obj = {
                applicantEmail,
                fileData: uploadFile.src,
                applicationId
            };

            if (type === "Resume") {
                obj.fileName = "resume.pdf";
                obj.fileCategory = "resumes";
            } else if (type === "CoverLetter") {
                obj.fileName = "cover-letter.pdf";
                obj.fileCategory = "cover-letters";
            }

            const result = await CreateUserFile(obj.applicantEmail, obj.applicationId, obj.fileName, obj.fileData, obj.fileCategory)
            console.log(result)
            if (result.status === 200 && result.message === "File uploaded successfully") {
                setIsUploadedSuccessfully(true)
                setIsFileUploaded(false)
                setUploadFile(null)
            }

            setShowSpinner(false)
        } catch (err) {
            console.log(err)
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        if (isUploadedSuccessfully) {
            setTimeout(() => {
                setIsUploadedSuccessfully(false)
            }, 5000);
        }
    }, [isUploadedSuccessfully])

    return (
        <>
            {
                isCreated ?
                    <h5 style={{ color: "white", margin: "20px" }}>You have already uploaded {type}</h5> : null
            }
            <div className="accordion-item">
                <h2 className="accordion-header" id={`panelsStayOpen-heading${itemCount}`}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${itemCount}`} aria-expanded="true" aria-controls={`panelsStayOpen-collapse${itemCount}`}>
                        Upload {type}
                    </button>
                </h2>
                <div id={`panelsStayOpen-collapse${itemCount}`} className="accordion-collapse collapse show" aria-labelledby={`panelsStayOpen-heading${itemCount}`}>
                    <div className="accordion-body">
                        {
                            !isFileUploaded ?
                                <>
                                    <input
                                        type="file"
                                        id={`fileInput${type}`}
                                        onChange={handleFileUpload}
                                        accept={"application/pdf"}
                                        required
                                    />
                                    <label htmlFor={`fileInput${type}`} className="btn uploadResume" type="button">Select file</label>
                                </>
                                : uploadFile ?
                                    <div className='updateBtn'>
                                        <div>
                                            <button className='btn updateCancel'>{uploadFile.name}</button>
                                            <button className='btn update updateCancel'
                                                style={{ color: "white" }}
                                                onClick={() => setShowPDF(true)}
                                            >
                                                View {type}
                                            </button>
                                        </div>
                                        <br />
                                        <button className="btn btn-primary update updateCancel"
                                            onClick={uploadBucketFile}
                                            disabled={!uploadFile.src}
                                        >
                                            Upload
                                        </button>
                                        <button className="btn btn-light updateCancel"
                                            style={{ background: "grey", color: "white" }}
                                            onClick={cancelUpload}
                                        >
                                            Cancel
                                        </button>
                                    </div> : null
                        }
                        {
                            isUploadedSuccessfully ?
                                <h6 style={{ color: "green", paddingTop: "10px" }}>File uploaded successfully</h6> : null
                        }
                    </div>
                </div>
            </div>


            {
                showPDF ?
                    <ViewPDF
                        pdfURL={uploadFile.src}
                        showPDF={showPDF}
                        setShowPDF={setShowPDF}
                    />
                    : null
            }

            {
                showSpinner ? <Spinner /> : null
            }
        </>
    )
}
