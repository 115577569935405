import React, { useContext } from 'react'
import { questions } from 'formQuestions'
import InputText from './InputText'
import InputEmail from './InputEmail'
import InputSelect from './InputSelect'
import InputDate from './InputDate'
import InputCountry from './InputCountry'
import InputState from './InputState'
import { ApplicationContext } from 'context'

export default function Index({ formValues, setFormValues, position, educationCount, setEducationCount }) {
    let navTab = "education"
    const { application } = useContext(ApplicationContext)

    const removeElementByValue = (valueToRemove) => {
        if (educationCount.length > 1) {
            const newArray = educationCount.filter(item => item !== valueToRemove);
            setEducationCount(newArray);
        }
    };

    const removeObjectByKey = (keyToRemove) => {
        setFormValues(prevValues => {
            if (prevValues[navTab]) {
                const { [keyToRemove]: removedItem, ...newTabValues } = prevValues[navTab];
                return {
                    ...prevValues,
                    [navTab]: newTabValues,
                };
            }
            return prevValues;
        });
    };

    return (
        <>
            <hr style={{ border: "5px solid white", marginTop: "20px" }} />
            {
                !application &&
                <div className="d-flex justify-content-end">
                    <button className='btn btn-danger'
                        onClick={() => {
                            removeElementByValue(position);
                            removeObjectByKey(position)
                        }}
                    >Remove</button>
                </div>
            }
            {
                questions[navTab].map(question => (
                    question.type === "text" ?
                        <InputText key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} position={position} /> :
                        question.type === "email" ?
                            <InputEmail key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} position={position} /> :
                            question.type === "select" ?
                                <InputSelect key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} position={position} /> :
                                question.type === "date" ?
                                    <InputDate key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} position={position} /> :
                                    question.type === "country" ?
                                        <InputCountry key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} position={position} /> :
                                        question.type === "state" ?
                                            <InputState key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} position={position} /> : null
                ))
            }

        </>
    )
}
