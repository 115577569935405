import React from 'react'

export default function Tabs({ navTab, setNavTab }) {

    return (
        <>
            <nav className="nav nav-pills flex-column flex-sm-row">
                <a
                    className={`flex-sm-fill text-sm-center nav-link ${navTab === "personalDetails" ? 'active' : ''}`}
                    onClick={() => setNavTab("personalDetails")}
                >
                    1. Personal details
                </a>
                <a
                    className={`flex-sm-fill text-sm-center nav-link ${navTab === "education" ? 'active' : ''}`}
                    onClick={() => setNavTab("education")}
                >
                    2. Education
                </a>
                <a
                    className={`flex-sm-fill text-sm-center nav-link ${navTab === "employmentDetails" ? 'active' : ''}`}
                    onClick={() => setNavTab("employmentDetails")}
                >
                    3. Employment details
                </a>
                <a
                    className={`flex-sm-fill text-sm-center nav-link ${navTab === "workVisaStatus" ? 'active' : ''}`}
                    onClick={() => setNavTab("workVisaStatus")}
                >
                    4. Work visa status
                </a>
            </nav>
        </>
    )
}
