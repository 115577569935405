import React, { useContext, useEffect, useState } from 'react'
import { ApplicationContext } from 'context'
import ViewPDF from 'MyProfile/viewPDF'

export default function ViewFile({ question }) {

    const { application } = useContext(ApplicationContext)

    const [viewFile, setViewFile] = useState(false)
    const [showPDF, setShowPDF] = useState(false)
    const [shouldShow, setShouldShow] = useState(false)
    const [inputDefaultValue, setInputDefaultValue] = useState('')
    const gcloudBucketLink = process.env.REACT_APP_GCLOUD_BUCKET_LINK

    let fileKey = Date.now()
    let management = "user"

    useEffect(() => {
        getDefaultValue(question.id, question.label)
    }, [question])

    const getDefaultValue = (questionId, label) => {
        var key;
        if (questionId === "resume") {
            key = "createResume"
        } else if (questionId === "coverLetter") {
            key = "createCoverLetter"
        }

        if ((application[key]?.didUserUpload) || (application[key]?.shouldCreate && application[key]?.isCreated)) {
            const filePath =
                questionId === "resume" ? "/resumes/resume.pdf" :
                    questionId === "coverLetter" ? "/cover-letters/cover-letter.pdf" : null
            setViewFile(`${gcloudBucketLink}${management}/${application.applicantEmail}${filePath}?key=${fileKey}`)
        } else if (application[key]?.shouldCreate && !application[key]?.isCreated) {
            setShouldShow(true)
            setInputDefaultValue(`Your ${label} will be created`)
        } else if (questionId === "resumeImage") {
            if (application?.isResumePhotoUploaded) {
                setViewFile(`${gcloudBucketLink}${management}/${application.applicantEmail}/resume-image/resume.png?key=${fileKey}`)
            } else {
                setShouldShow(true)
                setInputDefaultValue('Choose')
            }
        }
    }

    return (
        <>
            {
                viewFile ?
                    <button type="button" className='btn btn-started'
                        onClick={() => setShowPDF(true)}
                    >
                        View
                    </button> :

                    shouldShow ?
                        <input
                            type={"text"}
                            className='form-control'
                            name={question.id}
                            placeholder={question.label}
                            defaultValue={inputDefaultValue}
                            disabled
                        /> : null

            }

            {
                showPDF ?
                    <ViewPDF
                        pdfURL={viewFile}
                        showPDF={showPDF}
                        setShowPDF={setShowPDF}
                    />
                    : null
            }
        </>
    )
} 
