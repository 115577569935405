import React from 'react'

export default function Footer(props) {


    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>JH</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                        {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
                        Designed for job aspirants
                    </div>
                </div>
            </footer>
        </>
    )
}
