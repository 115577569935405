/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { ProfileContext } from 'context'
import { useNavigate } from 'react-router-dom'
import Register from './register'
import Tabs from './tabs'
import './myApplications.css'

export default function Index(props) {

    const navigate = useNavigate()

    const { profileData } = useContext(ProfileContext)
    const [navTab, setNavTab] = useState('personalDetails')

    useEffect(() => {
        // Set the background color when the component mounts
        document.body.style.backgroundColor = '#663800';

        // Cleanup function to unset the background color when the component unmounts
        return () => {
            document.body.style.backgroundColor = ''; // Reset to the default background color
        };
    }, []); // Empty dependency array to ensure the effect runs only on mount and unmount


    useEffect(() => {
        if (!profileData) {
            navigate('/login')
        }
    }, [profileData, navigate])



    return (
        <>
            <div className='myApplications'>
                <Tabs navTab={navTab} setNavTab={setNavTab} />
                <div className='m-5'>
                    <Register navTab={navTab} setNavTab={setNavTab}/>
                </div>
            </div>
        </>
    )
}
