import React, { useContext } from 'react'
import Dropdown from './dropdown'
import Employees from './employees'
import { AdminProfileContext } from 'context'

export default function Main() {

    const { sideBarActive } = useContext(AdminProfileContext)

    return (
        <>

            <div className={`p-4 p-md-5 pt-5 content ${sideBarActive ? 'active' : ''}`}>
                <Dropdown />
                <Employees />
            </div>
        </>
    )
}
