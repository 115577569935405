import { useState } from 'react';
import UploadResume from './upload-resume'
import ViewResumes from './view-resumes'

export default function Index(props) {

    const [fileKey, setFileKey] = useState(Date.now());

    return (
        <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* <UploadResume setFileKey={setFileKey} /> */}
            <ViewResumes fileKey={fileKey} setFileKey={setFileKey} />
        </div>
    )
}
