import Spinner from 'Spinner';
import { useEffect, useRef, memo } from 'react';

function InfiniteScroll({ children, fetchDataFunction, isLoading, page }) {

  const observerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading && page !== null) {
        await fetchDataFunction();
      }
    };

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchData();
      }
    }, options);

    if (observerRef.current) {
      observerRef.current.observe(document.getElementById('observe-element'));
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [fetchDataFunction]);

  return (
    <>
      {children}
      <div id="observe-element" style={{ height: '10px' }} />
      {isLoading && <Spinner />}
    </>
  )
}

export default memo(InfiniteScroll);