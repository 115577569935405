import React, { useContext } from 'react'
import Dropdown from './dropdown'
import Updates from './updates'
import { UpdatesContext, SubDomainContext } from 'context'
import GiveFeedback from './giveFeedback'

export default function Main() {

    const { sideBarActive } = useContext(UpdatesContext)
    const { subDomain } = useContext(SubDomainContext)

    return (
        <>

            <div className={`p-4 p-md-5 pt-5 content ${(subDomain === "user" || subDomain === "employee") ? 'user' : ''} ${sideBarActive ? 'active' : ''}`}>
                <Dropdown />
                <GiveFeedback />
                <Updates />
            </div>
        </>
    )
}
