import { GetAllApplications, GetApplicationOptions } from 'api/admin';
import { GetEmployeeAssignedApplications } from 'api/employee';
import { SubDomainContext, ProfileContext, } from 'context';
import React, { useState, createContext, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom';

export const adminProfileContext = createContext();

export default function AdminProfile({ children }) {

    const { pathname } = useLocation();
    const { profileData } = useContext(ProfileContext)
    const { subDomain } = useContext(SubDomainContext)

    const [isInitalFilterSet, setIsInitalFilterSet] = useState()
    const [page, setPage] = useState(0)
    const [optionsPage, setOptionsPage] = useState(0)
    const [sideBarActive, setSideBarActive] = useState()
    const [filters, setFilters] = useState({})
    const [applications, setApplications] = useState([])
    const [applicationOptions, setApplicationOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    const limit = 100;

    // console.log(filters) 

    useEffect(() => {
        if ((pathname === "/myClients" || pathname === "/myClients/") && profileData) {
            if (!isInitalFilterSet) {
                if (subDomain === "employee") {
                    setFilters(prev => ({ ...prev, employeeId: profileData.employeeId, sortDirection: 'desc' }))
                    setIsInitalFilterSet(true)
                }
            }
        }
    }, [pathname, profileData, subDomain, isInitalFilterSet])

    useEffect(() => {
        // console.log(filters)
        setApplications([])
        setPage(0)
    }, [JSON.stringify(filters), isInitalFilterSet])

    useEffect(() => {
        if(!profileData){
            setIsInitalFilterSet()
            setPage(0)
            setOptionsPage(0)
            setSideBarActive()
            setFilters({})
            setApplications([])
            setApplicationOptions([])
            setIsLoading(false)
            setIsOptionsLoading(false)
        }
    }, [profileData])

    const fetchApplicationData = async () => {
        if (subDomain === "employee" && isInitalFilterSet) {
            await fetchEmployeeAssignedApplications()
        } else if (subDomain === "admin") {
            await fetchAllApplications()
        }
    }

    const fetchEmployeeAssignedApplications = async () => {
        try {
            if (page === null || isLoading || !profileData) return;
            setIsLoading(true)
            const result = await GetEmployeeAssignedApplications(filters, page, limit)
            console.log(result)
            if (result?.status === 200 && result?.data) {
                const newApplications = result.data.applications.filter(newApp => {
                    // Check if the newApp is not already in applications
                    return !applications.some(existingApp => existingApp._id === newApp._id);
                });

                setApplications(prev => [...prev, ...newApplications]);
                if (result.data.nextPage) {
                    setPage(prev => prev + 1)
                } else {
                    setPage(null)
                }
            } else {
                setPage(null)
            }
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const fetchAllApplications = async () => {
        try {
            if (page === null || isLoading || !profileData) return;
            setIsLoading(true)
            const result = await GetAllApplications(filters, page, limit)
            // console.log(result)
            if (result?.status === 200 && result?.data) {
                const newApplications = result.data.applications.filter(newApp => {
                    // Check if the newApp is not already in applications
                    return !applications.some(existingApp => existingApp._id === newApp._id);
                });

                setApplications(prev => [...prev, ...newApplications]);
                if (result.data.nextPage) {
                    setPage(prev => prev + 1)
                } else {
                    setPage(null)
                }
            } else {
                setPage(null)
            }
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const fetchApplicationsOptions = async () => {
        try {
            if (optionsPage === null || isOptionsLoading || !profileData) return;
            setIsOptionsLoading(true)
            const result = await GetApplicationOptions(optionsPage, limit)
            console.log(result)
            if (result?.status === 200 && result?.data) {
                setApplicationOptions(prev => [...prev, ...result.data.applications]);
                if (result.data.nextPage) {
                    setOptionsPage(prev => prev + 1)
                } else {
                    setOptionsPage(null)
                }
            } else {
                setOptionsPage(null)
            }
            setIsOptionsLoading(false)
        } catch (err) {
            console.log(err)
            setIsOptionsLoading(false)
        }
    }

    const providerItem = {
        applications,
        fetchApplicationData,
        isLoading,
        page,
        filters,
        setFilters,
        sideBarActive,
        setSideBarActive,
        applicationOptions,
        fetchApplicationsOptions,
        isOptionsLoading
    };

    return (
        <adminProfileContext.Provider value={providerItem}>
            {children}
        </adminProfileContext.Provider>
    )
}
