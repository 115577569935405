import React from 'react'

export default function About(props) {


    return (
        <>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="row">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                            <h3>Empowering students' job success through personalized profile marketing.</h3>
                            <p className="fst-italic">
                                Our personalized profile marketing equips students with an irresistible advantage, unlocking doors to their dream careers.
                            </p>
                            <ul>
                                <li><i className="ri-check-double-line"></i>We go beyond traditional career services by actively applying to jobs on behalf of our clients. This hands-on approach saves our student clients valuable time and effort, giving them a competitive edge in the job market.</li>
                                <li><i className="ri-check-double-line"></i>We understand the significance of a well-crafted resume and cover letter in making a strong impression. Our team of skilled writers works closely with each student to highlight their strengths and achievements, ensuring their applications stand out from the competition.</li>
                                <li><i className="ri-check-double-line"></i>In addition to applying for jobs, we focus on building a compelling online presence for our clients. Through various channels and platforms, we showcase their skills, accomplishments, and potential, making them more visible and attractive to potential employers.</li>
                            </ul>
                            <p>
                                With these key points, our company aims to provide a holistic and effective approach to help students market their profiles, increase their visibility, and maximize their chances of success in the competitive job market.
                            </p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
