import React, { useContext, useState, useEffect } from 'react';
import { LogInUser, SendVerificationEmail } from 'api/user';
import { ProfileContext } from 'context';
import Spinner from 'Spinner';
import SendForgotPassword from 'LogIn/modal/forgotPassword';

export default function LogInForm() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showError, setShowError] = useState(false)
    const [userId, setUserId] = useState()
    const [showEmailNotVerifiedError, setShowEmailNotVerifiedError] = useState(false)
    const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [passwordType, setPasswordType] = useState("password");

    const { setProfileData, setToken } = useContext(ProfileContext)

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }

    useEffect(() => {
        setShowError(false)
        setShowEmailNotVerifiedError(false)
        setIsVerificationEmailSent(false)
    }, [email, password])

    const handleOnSubmit = async (e) => {
        try {
            e.preventDefault();
            setShowError(false)
            setIsVerificationEmailSent(false)
            setShowEmailNotVerifiedError(false)
            setShowLoader(true)

            const result = await LogInUser(email, password)
            // console.log(result)
            setShowLoader(false)

            // console.log(result)
            if ((result.status === 200) && (result.user._id)) {
                if (result.user.confirmed) {
                    setProfileData(result.user)
                    setToken(result.token)
                }
            } else if ((result.status === 400) && (result.message === "User credentials not found")) {
                setShowError(true)
            } else if ((result.status === 400) && (result.message === "user email not confirmed")) {
                setShowEmailNotVerifiedError(true)
                setUserId(result.userId)
            }

        } catch (err) {
            console.log(err)
            setShowLoader(false)
        }
    }

    const resendVerificationEmail = async () => {
        try {
            setShowLoader(true)
            const result = await SendVerificationEmail(userId, email)
            if (result.status === 200 && result.message === "verification email sent") {
                setIsVerificationEmailSent(true)
                setUserId()
            }
            setShowLoader(false)
        } catch (err) {
            console.log(err)
            setShowLoader(false)
        }
    }

    return (
        <>
            <p className="small">or use your email account:</p>
            <form id="sign-in-form" onSubmit={handleOnSubmit} >
                <input type="email" placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value); }} required />
                <div className='password-input'>
                    <input type={passwordType} placeholder="Password" value={password}
                        onChange={(e) => setPassword(e.target.value)} required />

                    <div className='password-eye-icon' onClick={togglePassword}
                        style={{ paddingRight: "30px" }}
                    >
                        {passwordType === "password" ? <i className="bi bi-eye-slash" /> : <i className="bi bi-eye" />}
                    </div>
                </div>

                {
                    isVerificationEmailSent ?
                        <h6 style={{ color: "red" }}>Verification email sent, please confirm your email</h6> :
                        showError ?
                            <h6 style={{ color: "red" }}>User credentials not found</h6> :
                            showEmailNotVerifiedError ?
                                <h6 style={{ color: "red", fontSize: "15px" }}>
                                    please verify your email or
                                    <span className='resendEmail' onClick={resendVerificationEmail}> click here  </span>
                                    to resend verification email.
                                </h6> : null
                }
                <p className="forgot-password" data-bs-toggle="modal" data-bs-target="#sendForgotPassword">Forgot your password?</p>
                <button className="control-button in" type='submit'>Sign In</button>
            </form>
            <SendForgotPassword />
            {
                showLoader ? <Spinner /> : null
            }
        </>
    )
}
