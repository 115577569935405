import React, { useContext } from 'react'
import InfiniteScroll from 'InfiniteScroll';
import { UpdatesContext, SubDomainContext } from 'context';

export default function Applications(props) {

    const { updates, fetchAllUpdates, isLoading, page } = useContext(UpdatesContext)
    const { subDomain } = useContext(SubDomainContext)

    function convertTimestamp(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${month} ${day}, ${year} ${hours}:${minutes}`;
    }

    return (
        <>
            <InfiniteScroll fetchDataFunction={fetchAllUpdates} isLoading={isLoading} page={page}>
                <div className='updates-main'>
                    {
                        updates.map(update =>
                            <div className="card updates-card" key={update._id}>
                                <h4 className="card-header">
                                    <div className='update-subject'>
                                        {update.subject}
                                    </div>
                                    <div>
                                        <span className='updateOn'>update on:</span>
                                        <span className='date'>
                                            {convertTimestamp(update.createdAt)}
                                        </span>
                                    </div>
                                </h4>
                                <div className="card-body">
                                    {
                                        (subDomain === "admin" || subDomain === "employee") ?
                                            <>
                                                {
                                                    update.employeeId ?
                                                        <p className="card-text"><strong>Employee Id:</strong> {update.employeeId}</p> : null
                                                }

                                                <p className="card-text"><strong>Application Id:</strong> {update.applicationId}</p>
                                            </> : null
                                    }
                                    <p className="card-text">{update.message}</p>
                                    {/* {
                                        subDomain === "user" ?
                                            <Link
                                                to={update.employeeId ? '/profile' : '/myApplications'}
                                                className="btn goToBtn"
                                                style={{ margin: "10px" }}
                                            >
                                                {
                                                    update.employeeId ? 'Go to My Profile' : 'Go to My Applications'
                                                }
                                            </Link> : null
                                    } */}
                                    {/* {
                                        (update.employeeId && (subDomain === "user" || subDomain === "employee")) ?
                                            <>
                                                <button className='btn btn-primary'
                                                    style={{ margin: "10px", minWidth: "180px" }}
                                                    data-bs-toggle="modal" data-bs-target={`#${update._id}`}
                                                >
                                                    Give Feedback
                                                </button>
                                                <Modal
                                                    id={update._id}
                                                    employeeId={update.employeeId}
                                                    applicationId={update.applicationId}
                                                    sendFeedBack={sendFeedBack}
                                                />
                                            </>
                                            : null
                                    } */}
                                </div>
                            </div>
                        )
                    }
                </div>
            </InfiniteScroll>
        </>
    )
}