import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";

export default function Loader() {

    const { pathname } = useLocation();
    const [showLoader, setShowLoader] = useState(false)


    useEffect(() => {
        setShowLoader(true)
    }, [pathname])

    useEffect(() => {
        if (showLoader) {
            setTimeout(() => {
                setShowLoader(false)
            }, 300);
        }
    }, [showLoader])


    return (
        <>
            {
                showLoader ?
                    <>
                        <div id="preloader"></div>
                    </> : null
            }
        </>
    )
}
