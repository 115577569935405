import React from 'react'

export default function BackToTop(props) {


    return (
        <>
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
                <i className="bi bi-arrow-up-short"></i>
            </a>
        </>
    )
}
