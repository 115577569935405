import React, { useState } from 'react'
import ViewImage from 'MyProfile/viewImage'

export default function ProfilePic({ profileData, subDomain }) {

    const [showImage, setShowImg] = useState()

    const imageKey = Date.now();
    const gcloudBucketLink = process.env.REACT_APP_GCLOUD_BUCKET_LINK
    const fileCategory = "logo-image"
    const fileName = "profile-logo.png"

    return (
        <>
            <div className="img-circle text-center mb-3 content_img">
                <img
                    src={`${gcloudBucketLink}${subDomain}/${profileData.email.toLowerCase()}/${fileCategory}/${fileName}?key=${imageKey}`}
                    alt="user profile" className="shadow"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/assets/img/default-profile.png";
                    }}
                    onClick={e => setShowImg(e.target.src)}
                />
            </div>
            {
                showImage ?
                    <ViewImage
                        showImage={showImage}
                        setShowImg={setShowImg}
                    /> : null
            }
        </>
    )
}
