import React, { useState, useContext } from 'react'
import { SubDomainContext } from 'context'
import { ApplicationFeedBack } from 'api/user'
import AlertBox from 'AlertBox'
import Spinner from 'Spinner'

export default function GiveFeedback(props) {

    const { subDomain } = useContext(SubDomainContext)
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    const [applicationId, setApplicationId] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const sendFeedBack = async (subject, message) => {
        try {
            if (!subject || !message || (subDomain === "employee" && !applicationId)) {
                setShowAlert("All fields are required")
                return;
            }
            setLoading(true)
            const result = await ApplicationFeedBack(subDomain.toUpperCase(), subject, message, applicationId)
            setLoading(false)
            if (result) {
                setShowAlert(result.message)
            }
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    return (
        <>
            <div className='d-flex justify-center'>
                <button className='btn btn-primary'
                    style={{ margin: "10px", minWidth: "180px" }}
                    data-bs-toggle="modal" data-bs-target="#giveFeedback"
                >
                    Give Feedback
                </button>
            </div>
            <div className="modal fade" id="giveFeedback" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">FeedBack Form</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                {
                                    subDomain === "employee" ?
                                        <div className="row mb-3">
                                            <label htmlFor="ApplicationId" className="form-label">Application id</label>
                                            <div>
                                                <input type="text" className="form-control" id="ApplicationId"
                                                    style={{ border: "0.05rem solid black" }}
                                                    value={applicationId}
                                                    placeholder={"Application id"}
                                                    onChange={(e) => setApplicationId(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div> : null
                                }
                                <div className="row mb-3">
                                    <label htmlFor="Subject" className="form-label">Subject</label>
                                    <div>
                                        <input type="text" className="form-control" id="Subject"
                                            style={{ border: "0.05rem solid black" }}
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="Message" className="form-label">Message</label>
                                    <div>
                                        <input type="text" className="form-control" id="Message"
                                            style={{ border: "0.05rem solid black" }}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary"
                                onClick={() => sendFeedBack(subject, message)}
                            >Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <AlertBox
                showAlert={showAlert}
                setShowAlert={setShowAlert}
            />
            {
                loading ? <Spinner /> : null
            }
        </>
    )
}
