import React, { useContext, useEffect } from 'react'
import "./profile.css"
import { ProfileContext } from 'context'
import { useNavigate } from 'react-router-dom'
import NavTabs from './nav-tabs'
import Account from './account'
import Password from './password'
import Resume from './resume'
import CoverLetter from './cover-letter'

export default function Index(props) {

    const { navTab, profileData } = useContext(ProfileContext)

    const navigate = useNavigate()

    useEffect(() => {
        if (!profileData) {
            navigate('/login')
        }
    }, [profileData, navigate])


    return (
        <div className='myProfile'>
            <section>
                <div className="container-fluid">
                    <div className="bg-white shadow rounded-lg d-block d-sm-flex mt-5">
                        {
                            profileData ? <NavTabs /> : null
                        }
                        <div className="tab-content p-4 p-md-5" id="v-pills-tabContent">
                            {
                                profileData ?
                                    (
                                        navTab === "Account" ? <Account /> :
                                            navTab === "Password" ? <Password /> :
                                                navTab === "Resume" ? <Resume /> :
                                                    navTab === "Cover letter" ? <CoverLetter /> : null
                                    ) : null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
