import React, { useState, useContext, useEffect } from 'react'
import { AdminProfileContext } from 'context';
import Select from 'react-select';

export default function InputSelect({ assignApplication }) {

    const { applicationOptions, fetchApplicationsOptions, isOptionsLoading } = useContext(AdminProfileContext)
    const [selectedOption, setSelectedOption] = useState('')

    var count = 0;

    useEffect(() => {
        if (applicationOptions.length === 0 && !isOptionsLoading && count === 0) {
            count++;
            fetchApplicationsOptions()
        }
    }, [applicationOptions, fetchApplicationsOptions, isOptionsLoading, count])

    return (
        <>
            <form>
                <div className="row mb-3">
                    <Select
                        options={applicationOptions}
                        isLoading={isOptionsLoading}
                        onChange={(option) => setSelectedOption(option)}
                        value={selectedOption}
                        onMenuScrollToBottom={fetchApplicationsOptions} // Load more options when scrolling to the bottom
                    />
                </div>
                <div className="d-grid gap-2 col-6 mx-auto">
                    <button className="btn btn-primary" type="button"
                        onClick={() => assignApplication(selectedOption.value)}
                    >Assign</button>
                </div>
            </form>
        </>
    )
}
