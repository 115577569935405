import React, { useState, createContext } from 'react'

export const subDomainContext = createContext();

export default function SubDomain({ children }) {

    const [subDomain, setSubDomain] = useState("loading");

    const providerItem = {
        subDomain, setSubDomain
    };

    return (
        <subDomainContext.Provider value={providerItem}>
            {children}
        </subDomainContext.Provider>
    )
}
