import React, { useState, useContext, useEffect } from 'react'
import { CreateApplication } from 'api/application';
import { ApplicationContext } from 'context'
import { questions } from 'formQuestions'
import Education from './education'
import InputText from './InputText'
import InputEmail from './InputEmail'
import InputSelect from './InputSelect'
import InputDate from './InputDate'
import InputTel from './InputTel'
import InputCountry from './InputCountry'
import InputState from './InputState'
import InputFile from './inputFile'
import Spinner from 'Spinner'
import AlertBox from 'AlertBox'
import "./register.css"
// import "./form.css"

export default function Index({ navTab, setNavTab }) {

    const { application, setApplication, fetchingApplications } = useContext(ApplicationContext)

    const [formValues, setFormValues] = useState({})
    const [resumeBuffer, setResumeBuffer] = useState()
    const [resumeImgBuffer, setResumeImgBuffer] = useState()
    const [coverLetterBuffer, setCoverLetterBuffer] = useState()
    const [fileActionResume, setFileActionResume] = useState()
    const [fileActionCoverLetter, setFileActionCoverLetter] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [isLastTab, setIsLastTab] = useState(false)
    const [educationCount, setEducationCount] = useState([0])

    useEffect(() => {
        if (application) {
            const initialState = Array.from({ length: application.education?.length }, (_, index) => index);
            setEducationCount(initialState)
        }
    }, [application])

    // useEffect(() => {
    //     console.log(formValues)
    // }, [formValues])

    useEffect(() => {
        for (const [index, key] of Object.keys(questions).entries()) {
            if (key === navTab) {
                if (index === (Object.keys(questions).length - 1)) {
                    setIsLastTab(true)
                } else {
                    setIsLastTab(false)
                }
            }
        }
    }, [navTab])

    const handleSubmit = async (event) => {
        event.preventDefault();

        for (const [index, key] of Object.keys(questions).entries()) {
            for (var i = 0; i < questions[key].length; i++) {
                if (key === "education") continue;
                const question = questions[key][i]
                const currentValue = formValues[key]?.[question.id]
                const response = await formValidation(question, currentValue)
                if (!response) return;
            }
        }

        const payload = { ...formValues }
        if (fileActionResume === "create-resume") {
            payload.createResume = true
            payload.resumeImgData = resumeImgBuffer
        } else if (fileActionResume === "upload-resume") {
            payload.resumeData = resumeBuffer
            payload.didUserUploadResume = true
        }
        if (fileActionCoverLetter === "create-coverLetter") {
            payload.createCoverLetter = true
        } else if (fileActionCoverLetter === "upload-coverLetter") {
            payload.coverLetterData = coverLetterBuffer
            payload.didUserUploadCoverLetter = true
        } else if (!fileActionCoverLetter) {
            payload.coverLetterData = ''
        }
        // console.log(fileActionCoverLetter, coverLetterBuffer, formValues)

        setShowLoader(true)
        const result = await CreateApplication(formValues.personalDetails, formValues.workVisaStatus, Object.values(formValues.education), formValues.employmentDetails,
            payload.createResume, payload.createCoverLetter, payload.resumeData, payload.coverLetterData, payload.resumeImgData, payload.didUserUploadResume, payload.didUserUploadCoverLetter)
        console.log(result)
        if (result.status === 200 && result.application) {
            setApplication(result.application)
        }

        setShowLoader(false)
    };

    const handleNext = async (event) => {
        event.preventDefault();

        for (var i = 0; i < questions[navTab].length; i++) {
            if (application) break;
            const question = questions[navTab][i]
            const currentValue = formValues[navTab]?.[question.id]
            const response = await formValidation(question, currentValue)
            if (!response) return;
        }

        var nextTab;

        for (const [index, key] of Object.keys(questions).entries()) {
            if (nextTab) {
                setNavTab(key)
                return
            }
            if (key === navTab) {
                nextTab = true;
            }
        }
    }

    const educationHandleNext = async (event, goToNextNavTab) => {
        event.preventDefault();

        for (var i = 0; i < questions[navTab].length; i++) {
            if (application) break;
            const question = questions[navTab][i]
            for (var j = 0; j < educationCount.length; j++) {
                const index = educationCount[j]
                const currentValue = formValues[navTab]?.[index]?.[question.id]
                const response = await formValidation(question, currentValue)
                if (!response) return;
            }
        }

        if (!goToNextNavTab) {
            return true;
        } else {
            var nextTab;

            for (const [index, key] of Object.keys(questions).entries()) {
                if (nextTab) {
                    setNavTab(key)
                    return
                }
                if (key === navTab) {
                    nextTab = true;
                }
            }
        }
    }

    const formValidation = async (question, currentValue) => {
        // console.log(question, currentValue)
        setShowAlert(false)

        if (question.type === "tel") {
            if (question.required && (!currentValue || !currentValue.status)) {
                setShowAlert(`Please provide a valid ${question.label}`)
                return
            }
        } else if (question.type === "file") {
            if (!currentValue) {
                if (question.id === "resume") {
                    if (!fileActionResume) {
                        setShowAlert(`Please select a value for ${question.label}`)
                        return
                    } else if (fileActionResume === `upload-${question.id}`) {
                        setShowAlert(`Please upload a ${question.label}`)
                        return;
                    }
                }
            }
        } else if (question.required) {
            if (!currentValue || (question.multiple && currentValue.length === 0)) {
                setShowAlert(`Please provide a value for ${question.label}`);
                return;
            }
        }

        return true;
    };

    if (fetchingApplications) return <Spinner />
    let renderEducations = []
    for (var i = 0; i < educationCount.length; i++) {
        const index = educationCount[i];
        renderEducations.push(<Education key={index} formValues={formValues} setFormValues={setFormValues} position={index} educationCount={educationCount} setEducationCount={setEducationCount} />)
    }

    return (
        <>
            <form className="registerForm row g-3"
                onSubmit={(e) => {
                    if (isLastTab) {
                        handleSubmit(e)
                    } else if (navTab === "education") {
                        educationHandleNext(e, true)
                    } else {
                        handleNext(e)
                    }
                }}>
                {
                    navTab === "education" ?
                        renderEducations
                        :
                        questions[navTab].map(question => (
                            question.type === "text" ?
                                <InputText key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} /> :
                                question.type === "email" ?
                                    <InputEmail key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} /> :
                                    question.type === "select" ?
                                        <InputSelect key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} /> :
                                        question.type === "date" ?
                                            <InputDate key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} /> :
                                            question.type === "tel" ?
                                                <InputTel key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} /> :
                                                question.type === "country" ?
                                                    <InputCountry key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} /> :
                                                    question.type === "state" ?
                                                        <InputState key={question.id} application={application} question={question} navTab={navTab} formValues={formValues} setFormValues={setFormValues} /> :
                                                        question.type === "file" ?
                                                            <InputFile key={question.id}
                                                                application={application}
                                                                question={question}
                                                                setResumeBuffer={setResumeBuffer}
                                                                setResumeImgBuffer={setResumeImgBuffer}
                                                                setCoverLetterBuffer={setCoverLetterBuffer}
                                                                fileActionResume={fileActionResume}
                                                                fileActionCoverLetter={fileActionCoverLetter}
                                                                setFileActionResume={setFileActionResume}
                                                                setFileActionCoverLetter={setFileActionCoverLetter}
                                                                navTab={navTab}
                                                                formValues={formValues}
                                                                setFormValues={setFormValues}
                                                            /> :
                                                            null
                        ))
                }

                {
                    navTab === "education" && !application &&
                    <div className="d-flex justify-content-center">
                        <button className='btn' style={{
                            background: "#ffc451",
                            width: "70%",
                            margin: "20px",
                            fontSize: "20px",
                            borderRadius: "20px"
                        }} type="button" onClick={async (e) => {
                            const result = await educationHandleNext(e)
                            if (result) {
                                const newIndex = (educationCount.slice(-1)[0] + 1) || 0
                                setEducationCount((prev) => [...prev, newIndex])
                            }
                        }
                        }>
                            Add another education
                        </button>
                    </div>
                }

                <div className="d-flex justify-content-center">
                    <button className={`btn ${(isLastTab && application) ? 'hideSubmitBtn' : ''}`} type="submit"
                        style={{
                            background: "#ffc451",
                            width: "70%",
                            margin: "20px",
                            fontSize: "20px",
                            borderRadius: "20px"
                        }}>
                        {
                            isLastTab ? 'submit' : "Next"
                        }
                    </button>
                </div>

                {
                    isLastTab && application &&
                    <h3 className='text-center' style={{ color: "white" }}>Thanks for adding your details we will get back to you in short time.</h3>
                }
            </form>

            <AlertBox
                showAlert={showAlert}
                setShowAlert={setShowAlert}
            />

            {
                showLoader ? <Spinner /> : null
            }
        </>
    )
}
