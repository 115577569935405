import React, { useState, useEffect } from 'react'
import { ContactUsMessage } from 'api/user'

export default function ContactUs(props) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const [error, setError] = useState(false)
    const [isMessageSent, setIsMessageSent] = useState(false)

    useEffect(() => {
        if (isMessageSent) {
            setTimeout(() => {
                setIsMessageSent(false)
            }, 5000);
        }
    }, [isMessageSent])

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setShowLoader(true)
            const result = await ContactUsMessage(name, email, subject, message) 
            // console.log(result)
            setShowLoader(false)
            if (result.status === 200) {
                setIsMessageSent(true)
                setError(false)
            } else {
                setError(true)
            }
        } catch (err) {
            console.log(err)
            setShowLoader(false)
            setIsMessageSent(false)
            setError(false)
        }
    }

    return (
        <>
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Contact</h2>
                        <p>Contact Us</p>
                    </div>

                    {/* <div>
                        <iframe style={{border: "0", width: "100%", height: "270px"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameBorder="0" allowFullScreen></iframe>
                    </div> */}

                    <div className="row mt-5">

                        <div className="col-lg-4">
                            <div className="info">
                                {/* <div className="address">
                                    <i className="bi bi-geo-alt"></i>
                                    <h4>Location:</h4>
                                    <p>A108 Adam Street, New York, NY 535022</p>
                                </div> */}

                                <div className="email" style={{ marginTop: "0px" }}>
                                    <i className="bi bi-envelope"></i>
                                    <h4>Email:</h4>
                                    <p>{process.env.REACT_APP_COMPANY_EMAIL}</p>
                                </div>

                                <div className="phone" style={{ display: "block" }}>
                                    <i className="bi bi-phone"></i>
                                    <h4>Call:</h4>
                                    <p>{process.env.REACT_APP_COMPANY_MOBILE_NUMBER}</p>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-8 mt-5 mt-lg-0">

                            <form className="php-email-form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject"
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                        required />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea className="form-control" name="message" rows="5" placeholder="Message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required />
                                </div>
                                <div className="my-3">
                                    {
                                        showLoader ?
                                            <div className="loading">Loading</div> : null
                                    }
                                    {
                                        error ?
                                            <div className="error-message">An error occurred, please try again</div> : null
                                    }
                                    {
                                        isMessageSent ?
                                            <div className="sent-message">Your message has been sent. Thank you!</div> : null
                                    }
                                </div>
                                <div className="text-center">
                                    <button type="submit">Send Message</button>
                                </div>
                            </form>

                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}
