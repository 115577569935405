import React, { useContext, useState } from 'react'
import { EmployeeDataContext } from 'context'
import InfiniteScroll from 'InfiniteScroll';
import AlertBox from 'AlertBox';
import { Link } from 'react-router-dom';
import Modal from './modal'

export default function Applications(props) {

    const { employees, fetchAllEmployees, isLoading, page, removeEmployee } = useContext(EmployeeDataContext)
    const [selectedEmployeeId, setSelectedEmployeeId] = useState()
    const [showAlert, setShowAlert] = useState()

    return (
        <>
            <InfiniteScroll fetchDataFunction={fetchAllEmployees} isLoading={isLoading} page={page}>
                <div className="row table-responsive">
                    <table className="table table-striped table-hover align-middle">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Employee Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Total Clients</th>
                                <th scope="col">Assign Application</th>
                                {/* <th scope="col">Enter Application id</th>
                                <th scope="col"></th> */}
                                <th scope="col">View</th>
                                <th scope="col">Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                employees.map((item, i) =>
                                    <tr key={item._id}>
                                        <th scope="row">{i + 1}</th>
                                        <th scope="row">{item.employeeId}</th>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.totalApplicationsAssigned}</td>
                                        {/* <AssignApplicationInput employeeId={item.employeeId} /> */}
                                        <td>
                                            <button className='btn btn-primary'
                                                onClick={() => setSelectedEmployeeId(item.employeeId)}
                                                data-bs-toggle="modal" data-bs-target={`#assignApplication`}
                                            >
                                                Assign Application
                                            </button>
                                        </td>
                                        <td>
                                            <Link className='btn'
                                                to={`/myEmployees/${item.employeeId}`}
                                                state={{ employee: item }}
                                                style={{ background: "rgb(210, 105, 30)", color: "white" }}
                                            >
                                                View
                                            </Link>
                                        </td>
                                        <td>
                                            <button className='btn btn-danger' type="btutton"
                                                onClick={() => removeEmployee(item.employeeId)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>

                </div>
            </InfiniteScroll>
            <Modal employeeId={selectedEmployeeId} />
            <AlertBox
                showAlert={showAlert}
                setShowAlert={setShowAlert}
            />
        </>
    )
}