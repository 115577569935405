import React from 'react'

export default function NavTab({ navTab, setNavTab }) {


    return (
        <>
            <nav className="nav nav-pills flex-column flex-sm-row">
                <button className={`flex-sm-fill text-sm-center nav-link ${navTab === "select" ? 'active' : 'unselected'}`}
                    onClick={() => setNavTab('select')}
                >
                    Select from list
                </button>
                <button className={`flex-sm-fill text-sm-center nav-link ${navTab === "applicationId" ? 'active' : 'unselected'}`}
                    onClick={() => setNavTab('applicationId')}
                >
                    Enter Application id
                </button>
            </nav>
        </>
    )
}
