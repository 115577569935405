import React, { useContext, useEffect, useState } from 'react'
import { FetchBucketFiles, DeleteBucketFile } from 'api/gcloud_bucket'
import { ProfileContext, SubDomainContext } from 'context'
import Spinner from 'Spinner'
import ViewPDF from 'MyProfile/viewPDF'

export default function ViewCoverLetter({ fileKey, setFileKey }) {

    const { navTab, profileData, coverLetterFile, setCoverLetterFile } = useContext(ProfileContext)
    const { subDomain } = useContext(SubDomainContext)
    const [showSpinner, setShowSpinner] = useState(false)
    var count = 0;

    const fetchFiles = async () => {
        try {
            setShowSpinner(true)
            const result = await FetchBucketFiles(profileData.email, "cover-letters", subDomain)

            // console.log(result)
            if (result.status === 200) {
                setCoverLetterFile(result.files)
                setFileKey(prevKey => prevKey + 1);
            }

            setShowSpinner(false)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteFile = async (fileName) => {
        try {
            setShowSpinner(true)
            const result = await DeleteBucketFile(profileData.email, "cover-letters", fileName, subDomain)
            if (result.status === 200) {
                await fetchFiles()
            }
            setShowSpinner(false)
        } catch (err) {
            console.log(err)
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        if (navTab === "Cover letter" && !coverLetterFile && count === 0) {
            count++;
            fetchFiles()
        }
    }, [coverLetterFile, navTab])


    return (
        <>
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo"
                    // onClick={() => {
                    //     if (!coverLetterFile) {
                    //         fetchFiles()
                    //     }
                    // }}
                    >
                        View cover letter
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                    <div className="accordion-body table-responsive">
                        {
                            coverLetterFile?.length > 0 ?
                                <table className="table table-hover">
                                    <tbody>
                                        {
                                            coverLetterFile &&
                                            coverLetterFile.map((item, i) => {
                                                return (
                                                    <tr key={item} className='tableRowFile'>
                                                        <th scope="row">{i + 1}</th>
                                                        <td className='fileName'>{item.match(/[^/]+$/)[0]}</td>
                                                        <td>
                                                            <ViewButton filePath={`${item}?key=${fileKey}`} />
                                                        </td>
                                                        {/* <td>
                                                            <button className='btn btn-danger'
                                                                onClick={() => deleteFile(item.match(/[^/]+$/)[0])}
                                                            >
                                                                delete
                                                            </button>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                :
                                <h5>No cover letters found</h5>
                        }
                        <br />
                        <button className='btn editProfile' onClick={fetchFiles}>reload</button>
                    </div>
                </div>
            </div>

            {
                showSpinner ? <Spinner /> : null
            }

        </>
    )
}


const ViewButton = ({ filePath }) => {

    const [showPDF, setShowPDF] = useState(false)

    return (
        <>
            <button className='btn editProfile'
                onClick={() => setShowPDF(true)}
            >
                View
            </button>

            {
                showPDF ?
                    <ViewPDF
                        pdfURL={`${process.env.REACT_APP_GCLOUD_BUCKET_LINK}${filePath}`}
                        showPDF={showPDF}
                        setShowPDF={setShowPDF}
                    />
                    : null
            }
        </>
    )
}