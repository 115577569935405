import React from 'react'
import File from './file'

export default function Index({ application }) {

    return (
        <>
            <div className="accordion" id="accordionPanelsStayOpenExample">
                {
                    application.createResume.shouldCreate ?
                        <File
                            type="Resume"
                            applicantEmail={application.applicantEmail}
                            itemCount={"One"}
                            applicationId={application.applicationId}
                            isCreated={application.createResume.isCreated}
                        /> : null
                }
                {
                    application.createCoverLetter.shouldCreate ?
                        <File
                            type="CoverLetter"
                            applicantEmail={application.applicantEmail}
                            itemCount={"Two"}
                            applicationId={application.applicationId}
                            isCreated={application.createCoverLetter.isCreated}
                        /> : null
                }
            </div>
        </>
    )
}
