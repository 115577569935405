import Footer from 'Footer'
import Loader from 'Loader'
import ScrollToTop from 'ScrollTotop'
import { AdminProfileProvider, ProfileProvider, SubDomainContext, UpdatesProvider, ApplicationProvider } from 'context'
import React, { useContext, useEffect } from 'react'
import Home from 'Home'
import LogIn from 'LogIn/management'
import Profile from 'MyProfile'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Header from 'Header/employee'
import MyApplications from 'MyApplications/management'
import ViewApplication from 'MyApplications/management/viewApplication'
import Updates from 'Updates'
import SetInitialPassword from 'SetInitialPassword'
import ForgotPassword from 'ForgotPassword'

export default function App({ subDomain }) {

    const { setSubDomain } = useContext(SubDomainContext)

    useEffect(() => {
        setSubDomain(subDomain)
    }, [subDomain, setSubDomain])

    return (
        <>
            <div className='app-employee' style={{ minHeight: "100vh" }}>
                <BrowserRouter>
                    <ProfileProvider>
                        <AdminProfileProvider>
                            <ApplicationProvider>
                                <UpdatesProvider>
                                    <Loader />
                                    <ScrollToTop />
                                    <Header />
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/login" element={<LogIn />} />
                                        <Route path="/profile" element={<Profile />} />
                                        <Route path="/myClients/:applicationId" element={<ViewApplication />} />
                                        <Route path="/myClients" element={<MyApplications />} />
                                        <Route path="/updates" element={<Updates />} />
                                        <Route path="/setInitialPassword/:employeeId/:password" element={<SetInitialPassword />} />
                                        <Route path="/forgotPassword/:token" element={<ForgotPassword />} />
                                        <Route path="*" element={<Navigate to="/" />} />
                                    </Routes>
                                </UpdatesProvider>
                            </ApplicationProvider>
                        </AdminProfileProvider>
                    </ProfileProvider>
                </BrowserRouter>
            </div>
            <Footer />
        </>
    )
}
