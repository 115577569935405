import axios from "./axiosInstance";
import { getAllBucketFilesRoute, deleteBucketFileRoute, storeBucketFileRoute } from "config";

export const FetchBucketFiles = async (email, fileCategory, management) => {
    try {
        const response = await axios.get(getAllBucketFilesRoute, { params: { email, fileCategory, management } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const DeleteBucketFile = async (email, fileCategory, fileName, management) => {
    try {
        const response = await axios.get(deleteBucketFileRoute, { params: { email, fileCategory, fileName, management } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const StoreBucketFile = async (email, fileData, fileName, fileCategory, management) => {
    try {
        const response = await axios.post(storeBucketFileRoute, { email, fileData, fileName, fileCategory, management })
        return response.data
    } catch (err) {
        console.log(err);
    }
}