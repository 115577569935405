import React, { useState } from 'react'
import './login.css'
import { useParams } from 'react-router-dom'
import Success from './success'
import Form from './form'

export default function Index() {

    const { employeeId, password: oldPassword } = useParams()
    const [isPasswordChanged, setIsPasswordChanged] = useState(false)

    return (
        isPasswordChanged ?
            <Success /> :
            <Form
                employeeId={employeeId}
                oldPassword={oldPassword}
                setIsPasswordChanged={setIsPasswordChanged}
            />
    )
}
