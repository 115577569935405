import React, { useContext, useEffect, useState } from 'react'
import { FetchBucketFiles, DeleteBucketFile } from 'api/gcloud_bucket'
import { ProfileContext, SubDomainContext } from 'context'
import Spinner from 'Spinner'
import ViewPDF from 'MyProfile/viewPDF'

export default function ViewResumes({ fileKey, setFileKey }) {

    const { navTab, profileData, resumeFile, setResumeFile } = useContext(ProfileContext)
    const { subDomain } = useContext(SubDomainContext)
    const [showSpinner, setShowSpinner] = useState(false)
    var count = 0;

    const fetchFiles = async () => {
        try {
            setShowSpinner(true)
            const result = await FetchBucketFiles(profileData.email, "resumes", subDomain)

            // console.log(result)
            if (result.status === 200) {
                setResumeFile(result.files)
                setFileKey(prevKey => prevKey + 1);
            }

            setShowSpinner(false)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteFile = async (fileName) => {
        try {
            setShowSpinner(true)
            const result = await DeleteBucketFile(profileData.email, "resumes", fileName, subDomain)
            if (result.status === 200) {
                await fetchFiles()
            }
            setShowSpinner(false)
        } catch (err) {
            console.log(err)
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        if (navTab === "Resume" && !resumeFile && count === 0) {
            count++;
            fetchFiles()
        }
    }, [resumeFile, navTab])


    return (
        <>
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo"
                    // onClick={() => {
                    //     if (!resumeFile) {
                    //         fetchFiles()
                    //     }
                    // }}
                    >
                        View Resume
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                    <div className="accordion-body table-responsive">
                        {
                            resumeFile?.length > 0 ?
                                <table className="table table-hover">
                                    <tbody>
                                        {
                                            resumeFile &&
                                            resumeFile.map((item, i) => {
                                                return (
                                                    <tr key={item} className='tableRowFile'>
                                                        <th scope="row">{i + 1}</th>
                                                        <td className='fileName'>{item.match(/[^/]+$/)[0]}</td>
                                                        <td>
                                                            <ViewButton filePath={`${item}?key=${fileKey}`} />
                                                        </td>
                                                        {/* <td>
                                                            <button className='btn btn-danger'
                                                                onClick={() => deleteFile(item.match(/[^/]+$/)[0])}
                                                            >
                                                                delete
                                                            </button>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                :
                                <h5>No resumes found</h5>
                        }
                        <br />
                        <button className='btn editProfile' onClick={fetchFiles}>reload</button>
                    </div>
                </div>
            </div>

            {
                showSpinner ? <Spinner /> : null
            }

        </>
    )
}


const ViewButton = ({ filePath }) => {

    const [showPDF, setShowPDF] = useState(false)

    return (
        <>
            <button className='btn editProfile'
                onClick={() => setShowPDF(true)}
            >
                View
            </button>

            {
                showPDF ?
                    <ViewPDF
                        pdfURL={`${process.env.REACT_APP_GCLOUD_BUCKET_LINK}${filePath}`}
                        showPDF={showPDF}
                        setShowPDF={setShowPDF}
                    />
                    : null
            }
        </>
    )
}