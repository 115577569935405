import { GetAllUpdates } from 'api/admin';
import React, { useState, createContext, useEffect, useContext } from 'react';
import { ProfileContext, SubDomainContext, ApplicationContext } from 'context';

export const updatesContext = createContext();

export default function Updates({ children }) {

    const { profileData } = useContext(ProfileContext)
    const { subDomain } = useContext(SubDomainContext)
    const { application } = useContext(ApplicationContext)
    const [page, setPage] = useState(0)
    const [sideBarActive, setSideBarActive] = useState()
    const [filters, setFilters] = useState({})
    const [updates, setUpdates] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const limit = 20;

    useEffect(() => {
        setUpdates([])
        setPage(0)
    }, [JSON.stringify(filters)])

    useEffect(() => {
        if (subDomain && profileData) {
            // console.log(subDomain, profileData)
            if (subDomain === "user") {
                setFilters(prev => ({
                    ...prev,
                    from: "USER",
                    applicationId: profileData._id,
                }))
            } else if (subDomain === "employee") {
                setFilters(prev => ({
                    ...prev,
                    from: "EMPLOYEE",
                    senderId: profileData._id
                }))
            }
        }
    }, [subDomain, application, profileData])

    useEffect(() => {
        if (!profileData) {
            setPage(0)
            setSideBarActive()
            setFilters({})
            setUpdates([])
            setIsLoading(false)
        }
    }, [profileData])

    const checkFilters = async () => {
        if (subDomain === "user") {
            if (filters.applicationId) { return true }
        } else if (subDomain === "employee") {
            if (filters.senderId) { return true }
        } else if (subDomain === "admin") {
            return true
        }
    }

    const fetchAllUpdates = async () => {
        try {
            if (page === null || isLoading || !profileData) return;
            const isAuthorized = await checkFilters()
            if (!isAuthorized) return
            setIsLoading(true)
            const result = await GetAllUpdates(filters, page, limit)
            // console.log(filters, result)
            if (result?.status === 200 && result?.data) {
                const newUpdates = result.data.updates.filter(newEmp => {
                    // Check if the newEmp is not already in updates
                    return !updates.some(existingApp => existingApp._id === newEmp._id);
                });

                setUpdates(prev => [...prev, ...newUpdates]);
                if (result.data.nextPage) {
                    setPage(prev => prev + 1)
                } else {
                    setPage(null)
                }
            } else {
                setPage(null)
            }
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const providerItem = {
        page, setPage,
        limit,
        sideBarActive, setSideBarActive,
        filters, setFilters,
        updates, setUpdates, isLoading,
        fetchAllUpdates
    };

    return (
        <updatesContext.Provider value={providerItem}>
            {children}
        </updatesContext.Provider>
    )
}
