import React, { useContext, useEffect, useState } from 'react'
import './login.css'
import { useNavigate } from 'react-router-dom'
import { SubDomainContext, ProfileContext } from 'context'
import InputTel from './inputTel'
import { SignUpEmployee } from 'api/admin'
import Spinner from 'Spinner'

export default function Index() {

    const navigate = useNavigate()

    const { subDomain } = useContext(SubDomainContext)

    const { profileData } = useContext(ProfileContext)

    const [name, setName] = useState('')
    const [mobileNumber, setMobileNumber] = useState({
        phoneNumber: "",
        countryCode: "",
        dialCode: "",
        status: false
    })
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!profileData) {
            navigate('/login')
        }
    }, [profileData, navigate])

    useEffect(() => {
        setError(false)
        setShowAlert(false)
    }, [email, name, mobileNumber])

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setError(false)
            setShowAlert(false)
            if (!name || !email || !mobileNumber.phoneNumber) {
                setError("All fields are required")
                return
            }
            if(!mobileNumber.status){
                setError("Phone number not valid")
                return
            }
            if (subDomain === "admin") {
                setIsLoading(true)
                const result = await SignUpEmployee(name, email, mobileNumber)
                console.log(result)
                if (result && result.status === 200) {
                    setShowAlert("Employee created successfully, password has been sent to registered email.")
                } else if (result && result.status === 400) {
                    setError(result.message)
                }
            }
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setError(err.message)
            setIsLoading(false)
        }
    }



    return (
        <>
            <div className='app-management-login createEmployee'>
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <div className="login100-form-title">
                                <span className="login100-form-title-1">
                                    Sign Up
                                </span>
                            </div>

                            <form className="login100-form validate-form" onSubmit={handleSubmit}>

                                <div className="wrap-input100 validate-input m-b-26">
                                    <span className="label-input100">Name</span>
                                    <input className="input100" type="text" name="name" placeholder="Enter name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                    <span className="focus-input100"></span>
                                </div>

                                <div className="wrap-input100 validate-input m-b-26">
                                    <span className="label-input100">Email</span>
                                    <input className="input100" type="email" name="email" placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <span className="focus-input100"></span>
                                </div>

                                <div className="wrap-input100 validate-input m-b-26">
                                    <span className="label-input100">Phone</span>
                                    <InputTel
                                        mobileNumber={mobileNumber}
                                        setMobileNumber={setMobileNumber}
                                    />
                                    <span className="focus-input100"></span>
                                </div>

                                <div className="container-login100-form-btn">
                                    {
                                        error ?
                                            <>
                                                <p style={{ color: "red" }}>{error}</p>
                                            </>
                                            : null
                                    }
                                    <br />
                                    {
                                        showAlert ?
                                            <h6 style={{ color: "green", fontSize: "15px" }}>
                                                Employee created, password sent to registered email.
                                            </h6> : null
                                    }

                                    <button className="login100-form-btn" type='submit'>
                                        Register
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {
                isLoading ?
                    <Spinner /> : null
            }
        </>
    )
}
