import axios from 'axios';
import { backendURL } from 'config'

const axiosInstance = axios.create({
    baseURL: backendURL, // Replace with your API URL
});

// Function to set the access token
export const setAccessToken = (token) => {
    axiosInstance.defaults.headers.common['x-access-token'] = token;
}

export default axiosInstance;