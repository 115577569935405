import { EmployeeDataContext } from 'context'
import React, { useContext } from 'react'

export default function Dropdown() {

    const { filters, setFilters } = useContext(EmployeeDataContext)

    const handleSort = async (sortBy, sortDirection) => {
        setFilters(prev => ({
            ...prev,
            sortBy,
            sortDirection
        }))
    }

    const renderSortIcon = (property) => {
        if (filters?.sortBy === property) {
            return filters.sortDirection === 'asc' ? <i className="bi bi-arrow-up" /> : <i className="bi bi-arrow-down" />;
        }
        return null;
    };


    return (
        <>
            <div className="main dropdown">
                <p className='sortBy'>Sort by </p>
                <button
                    className="btn btn-white dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {
                        filters?.sortBy === 'totalApplicationsAssigned'
                            ? <>Clients count {renderSortIcon('totalApplicationsAssigned')}</>
                                : filters?.sortDirection === 'asc'
                                    ? 'Old Employees'
                                    : 'Latest Employees'
                    }
                </button>
                <ul className="dropdown-menu">
                    <li><button className="dropdown-item" type="button" onClick={() => handleSort('totalApplicationsAssigned', 'asc')}>Clients count <i className="bi bi-arrow-up" /></button></li>
                    <li><button className="dropdown-item" type="button" onClick={() => handleSort('totalApplicationsAssigned', 'desc')}>Clients count <i className="bi bi-arrow-down" /></button></li>
                    <li><button className="dropdown-item" type="button" onClick={() => handleSort('', 'desc')}>Latest Employees</button></li>
                    <li><button className="dropdown-item" type="button" onClick={() => handleSort('', 'asc')}>Old Employees</button></li>
                </ul>
            </div>
            <br />
        </>
    )
}
