import React, { useEffect } from 'react'

export default function AlertBox({ showAlert, setShowAlert }) {

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false)
            }, 3000);
        }
    }, [showAlert, setShowAlert])


    return (
        <>
            {
                showAlert ?
                    <div className="toast-container position-fixed p-3 top-0 start-50 translate-middle-x" id="toastPlacement" data-original-class="toast-container position-absolute p-3">
                        <div className="toast show align-items-center text-white bg-warning border-0" role="alert" aria-live="assertive" aria-atomic="true">
                            <div className="d-flex justify-content-center">
                                <div className="toast-body">
                                    {showAlert}
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
        </>
    )
}
