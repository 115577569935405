import { ProfileContext } from 'context'
import React, { useContext, useState, useEffect } from 'react'
import IntlTelInput from 'react-intl-tel-input'

export default function InputTel({ application, question, navTab, formValues, setFormValues }) {

    const { mobileNumber } = useContext(ProfileContext)
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)

    useEffect(() => {
        const inputElement = document.querySelector('input[type="tel"]');

        if (inputElement) {
            inputElement.required = question.required;
        }
    }, [question]);

    const handlePhoneNumberChange = (status, phoneNumber, obj) => {
        // console.log(status, phoneNumber, obj)

        setFormValues(prevValues => ({
            ...prevValues,
            [navTab]: {
                ...formValues[navTab],
                [question.id]: {
                    ...prevValues[navTab]?.[question.id],
                    countryCode: obj.iso2,
                    dialCode: obj.dialCode,
                    status,
                    phoneNumber
                }
            }
        }));


        if (phoneNumber) {
            if (status) {
                setIsValidPhoneNumber(true)
            } else {
                setIsValidPhoneNumber(false)
            }
        } else {
            setIsValidPhoneNumber(true)
        }
    }

    const handleSelectFlag = async (currentNumber, seletedCountryData, fullNumber, isValid) => {
        // console.log({ currentNumber, seletedCountryData, fullNumber, isValid })

        setFormValues(prevValues => ({
            ...prevValues,
            [navTab]: {
                ...formValues[navTab],
                [question.id]: {
                    ...prevValues[navTab]?.[question.id],
                    countryCode: seletedCountryData.iso2,
                    dialCode: seletedCountryData.dialCode,
                    status: isValid
                }
            }
        }));


        if (formValues[navTab]?.[question.id]?.['phoneNumber']) {
            if (isValid) {
                setIsValidPhoneNumber(true)
            } else {
                setIsValidPhoneNumber(false)
            }
        } else {
            setIsValidPhoneNumber(true)
        }
    }

    return (
        <>

            <div className="col-md-4">
                <label htmlFor={question.id} className="form-label" style={{ color: "white" }}>{question.label}
                    {
                        question.required ? <span style={{ color: "red" }}> *</span> : null
                    }
                </label>
                <div className='phone'>
                    <IntlTelInput
                        className={"form-control"}
                        preferredCountries={['us', 'in', 'gb',]}
                        value={application?.[navTab]?.[question.id]?.['phoneNumber'] || formValues[navTab]?.[question.id]?.['phoneNumber'] || ''}
                        placeholder={""}
                        type={"number"}
                        format={true}
                        onPhoneNumberChange={handlePhoneNumberChange}
                        autoHideDialCode={true}
                        onSelectFlag={handleSelectFlag}
                        geoIpLookup={""}
                        defaultCountry={application?.[navTab]?.[question.id]?.['countryCode'] || formValues[navTab]?.[question.id]?.['countryCode'] || mobileNumber?.countryCode || ''}
                        disabled={application}
                    />
                    {
                        formValues[navTab]?.[question.id]?.['phoneNumber'] ?
                            isValidPhoneNumber ?
                                <i className={`bi bi-check-circle text-center mr-1 success`} /> :
                                <i className={`bi bi-x-circle text-center mr-1 fail`} /> :
                            null
                    }
                </div>
            </div>
        </>
    )
}
