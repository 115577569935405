import React from 'react'

export default function InputSelect({ application, question, navTab, formValues, setFormValues, position }) {

    const handleInputChange = event => {
        const { id, options, multiple } = event.target;

        const selectedOptions = Array.from(options)
            .filter(option => option.selected && option.value !== "")
            .map(option => option.value);

        setFormValues(prevValues => {
            if (navTab === 'education') {
                const existingValues = prevValues[navTab]?.[position] || {};
                return {
                    ...prevValues,
                    [navTab]: {
                        ...formValues[navTab],
                        [position]: {
                            ...existingValues,
                            [id]: multiple ? selectedOptions : (selectedOptions[0] || "")
                        }
                    }
                };
            }
        });
    };

    return (
        <>
            <div className="col-md-4">
                <label htmlFor={question.id} className="form-label" style={{ color: "white" }}>{question.label}
                    {
                        question.required ? <span style={{ color: "red" }}> *</span> : null
                    }
                </label>
                <select id={question.id} className="form-select" required={question.required} multiple={question.multiple}
                    value={application?.[navTab]?.[position]?.[question.id] || formValues[navTab]?.[position]?.[question.id]}
                    onChange={handleInputChange}
                    disabled={application}
                >
                    <option value="">
                        {
                            question.multiple ? 'Choose one or more' : 'Choose...'
                        }
                    </option>
                    {
                        question.options.map(option =>
                            <option value={option} key={option}>{option}</option>
                        )
                    }
                </select>
            </div>
        </>
    )
}
