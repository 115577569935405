import React, { useContext } from 'react'
import { SubDomainContext, ProfileContext } from 'context'
import { UpdateUserProfile } from 'api/user'
import { UpdateEmployeeProfile } from 'api/employee'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

export default function Account(props) {

    const { profileData, setProfileData, editProfile, setEditProfile, resetProfile,
        name, setName, mobileNumber, setMobileNumber } = useContext(ProfileContext)

    const { subDomain } = useContext(SubDomainContext)

    const handlePhoneNumberChange = (status, phoneNumber, obj) => {
        // console.log(status, phoneNumber, obj)
        setMobileNumber(prev => ({
            ...prev,
            phoneNumber,
            status: true,
            countryCode: obj.iso2,
            dialCode: obj.dialCode,
        }));
        if (phoneNumber) {
            setMobileNumber(prev => ({
                ...prev,
                status
            }));
        }
    }

    const updateProfile = async () => {
        try {
            if (!mobileNumber?.status) return;
            var result;
            if (subDomain === "employee") {
                result = await UpdateEmployeeProfile(name, mobileNumber)
            } else if (subDomain === "user") {
                result = await UpdateUserProfile(name, mobileNumber)
            }
            // console.log(result)
            if (result.status === 200 && result.data._id) {
                setProfileData(result.data)
                setEditProfile(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleSelectFlag = async (currentNumber, seletedCountryData, fullNumber, isValid) => {
        // console.log({ currentNumber, seletedCountryData, fullNumber, isValid })
        setMobileNumber(prev => ({
            ...prev,
            status: true,
            countryCode: seletedCountryData.iso2,
            dialCode: seletedCountryData.dialCode,
        }));
        if (mobileNumber?.phoneNumber) {
            setMobileNumber(prev => ({
                ...prev,
                status: isValid
            }));
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                <h3 className="mb-4">Account Settings</h3>
                <div className="row">
                    {
                        subDomain !== "admin" ?
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        disabled={!editProfile}
                                    />
                                </div>
                            </div> : null
                    }
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email"
                                className="form-control"
                                defaultValue={profileData.email}
                                disabled={true}
                            />
                        </div>
                    </div>
                    {
                        subDomain !== "admin" ?
                            <div className="col-md-12">
                                <label>Phone number</label>
                                <div className="form-group phone">
                                    <IntlTelInput
                                        preferredCountries={['us', 'in', 'gb',]}
                                        inputClassName={"form-control"}
                                        value={(mobileNumber?.phoneNumber && mobileNumber?.phoneNumber.toString()) || ''}
                                        placeholder={"Enter Your Number"}
                                        type={"tel"}
                                        format={true}
                                        disabled={!editProfile}
                                        onPhoneNumberChange={handlePhoneNumberChange}
                                        autoHideDialCode={true}
                                        onSelectFlag={handleSelectFlag}
                                        geoIpLookup={""}
                                        defaultCountry={mobileNumber?.countryCode || ''}
                                    />
                                    {
                                        (editProfile && mobileNumber?.phoneNumber) ?
                                            mobileNumber?.status ?
                                                <i className={`bi bi-check-circle text-center mr-1 success`} /> :
                                                <i className={`bi bi-x-circle text-center mr-1 fail`} /> :
                                            null
                                    }
                                </div>
                            </div> : null
                    }
                </div>
                {
                    editProfile ?
                        <div className='updateBtn'>
                            <button className="btn btn-primary update updateCancel"
                                onClick={updateProfile}
                                disabled={!mobileNumber?.status}
                            >
                                Update
                            </button>
                            <button className="btn btn-light updateCancel"
                                onClick={resetProfile}
                            >
                                Cancel
                            </button>
                        </div> :

                        subDomain !== "admin" ?
                            <button
                                className="btn btn-primary editProfile"
                                onClick={() => setEditProfile(true)}>
                                Edit Profile
                            </button> : null
                }

            </div>
        </>
    )
}
