import React, { useEffect, useState } from 'react'
import './login.css'
import Spinner from 'Spinner'
import { ResetPassword } from 'api/forgotPassword'

export default function Form({ token, setIsPasswordChanged }) {

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordType, setPasswordType] = useState("password");
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState()

    useEffect(() => {
        setError(false)
        setMessage()
        if (password && confirmPassword) {
            setError(password !== confirmPassword);
            setMessage("passwords do not match")
        } else {
            setError(false);
        }
    }, [password, confirmPassword])

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }

    const changeUserPassword = async (e) => {
        try {
            e.preventDefault()

            if (!confirmPassword || !password) return;
            if (confirmPassword !== password) return;

            setError()
            setMessage()

            setIsLoading(true)
            const result = await ResetPassword(token, password)
            // console.log(result)
            setIsLoading(false)

            setError(result?.status === 200 ? false : true)
            setIsPasswordChanged(result?.status === 200 ? true : false)
            setMessage(result?.message)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    return (
        <>
            <div className='app-management-login createEmployee'>
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <div className="login100-form-title">
                                <span className="login100-form-title-1">
                                    Set Your Password
                                </span>
                            </div>

                            <form className="login100-form validate-form setInitialPassword" onSubmit={changeUserPassword}>

                                <div className="wrap-input100 validate-input m-b-26 password-input">
                                    {/* <span className="label-input100">Password</span> */}
                                    <input className="input100" type={passwordType} name="password" placeholder="Enter new password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <div className='password-eye-icon' onClick={togglePassword}>
                                        {passwordType === "password" ? <i className="bi bi-eye-slash" /> : <i className="bi bi-eye" />}
                                    </div>
                                    <span className="focus-input100"></span>
                                </div>

                                <div className="wrap-input100 validate-input m-b-26 password-input">
                                    {/* <span className="label-input100">Confirm Password</span> */}
                                    <input className="input100" type={passwordType} name="confirmPassword" placeholder="Enter confirm password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                    <div className='password-eye-icon' onClick={togglePassword}>
                                        {passwordType === "password" ? <i className="bi bi-eye-slash" /> : <i className="bi bi-eye" />}
                                    </div>
                                    <span className="focus-input100"></span>
                                </div>

                                <div className="container-login100-form-btn">
                                    {
                                        error ?
                                            <>
                                                <p style={{ color: "red" }}>{message}</p>
                                            </>
                                            : null
                                    }
                                    <br />

                                    <button className="login100-form-btn" type='submit'>
                                        Change password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {
                isLoading ?
                    <Spinner /> : null
            }
        </>
    )
}
