import React from 'react'
import ProfilePic from './profilePic'

export default function NavTabs({ profileData }) {

    return (
        <div className="profile-tab-nav border-right">
            <div className="p-4">
                <ProfilePic profileData={profileData} subDomain={"employee"} />
                <h4 className="text-center userProfileName">{profileData.name}</h4>
            </div>
            <div className="nav flex-column nav-pills" id="v-pills-tab">
                <div className={`nav-link active`}>
                    <i className={`bi bi-person text-center mr-1`} />
                    Account
                </div>
            </div>
        </div>
    )
}