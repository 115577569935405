import React from 'react'

export default function Services(props) {


    return (
        <>
            <section id="services" className="services">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Services</h2>
                        <p>Check our Services</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bxl-dribbble"></i></div>
                                <h4><a href="">Comprehensive Profile Marketing</a></h4>
                                <p>Enhance your professional image and reach new career heights with our expertly crafted marketing profiles.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-file"></i></div>
                                <h4><a href="">Resume Crafting</a></h4>
                                <p>Expertly crafted resumes that highlight your skills, experience, and achievements, helping you stand out in a competitive job market.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-tachometer"></i></div>
                                <h4><a href="">Cover Letter Crafting</a></h4>
                                <p>Compelling cover letters that capture the attention of employers, showcasing your unique qualifications and motivation for the desired position.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-world"></i></div>
                                <h4><a href="">Trainings</a></h4>
                                <p> Comprehensive and interactive training programs designed to enhance your professional skills, expand your knowledge base, and accelerate your career growth</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}
