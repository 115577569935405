import React from 'react'
import News from './News'
import Pricing from './pricing'

export default function Home(props) {


    return (
        <>


            <section id="hero">
                <News />
                <section className="d-flex align-items-center justify-content-center" style={{paddingTop: "0px"}}>
                    <div className="container" data-aos="fade-up">

                        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                            <div className="col-xl-6 col-lg-8">
                                <h1>Unlock Your Potential Find Your Perfect Placement<span>.</span></h1>
                                <h2>We are a team dedicated to helping you find jobs</h2>
                            </div>
                        </div>

                        <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-store-line"></i>
                                    <h3><a href="">Marketing</a></h3>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-bar-chart-box-line"></i>
                                    <h3><a href="">Resume creation</a></h3>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-calendar-todo-line"></i>
                                    <h3><a href="">Cover letter</a></h3>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-paint-brush-line"></i>
                                    <h3><a href="">Training</a></h3>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </section>
        </>
    )
}
