import axios from "./axiosInstance";
import { forgotPasswordRoute, resetPasswordRoute } from "config";

export const ForgotPassword = async (management, email) => {
    try {

        const response = await axios.get(forgotPasswordRoute, { params: { management, email } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const ResetPassword = async (token, password) => {
    try {
        const response = await axios.post(resetPasswordRoute, { token, password })
        return response.data
    } catch (err) {
        console.log(err);
    }
}
