import React from 'react'

export default function Pricing(props) {


    return (
        <>
            <section id="pricing" className="services">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Pricing</h2>
                        <p>Check our Prices</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-world"></i></div>
                                <h4><a href="">Profile Marketing</a></h4>
                                <h5><strong>$80 per month </strong> <br />(Discounted to <strong>$65</strong> until {process.env.REACT_APP_DISCOUNT_MONTH} end)</h5>
                                <p style={{ width: "265px" }}></p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bxl-dribbble"></i></div>
                                <h4><a href="">Resume & Cover Letter Crafting together</a></h4>
                                <h5>Only <strong>25$</strong></h5>
                                <p style={{ width: "265px" }}></p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-file"></i></div>
                                <h4><a href="">Resume Crafting</a></h4>
                                <h5>Only <strong>20$</strong> per resume</h5>
                                <p style={{ width: "265px" }}></p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-tachometer"></i></div>
                                <h4><a href="">Cover Letter Crafting</a></h4>
                                <h5>Only <strong>10$</strong> per cover letter</h5>
                                <p style={{ width: "265px" }}></p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}
