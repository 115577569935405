import React, { useEffect, useState } from 'react';
import AppUser from 'App/user';
import AppEmployee from 'App/employee';
import AppAdmin from 'App/admin';
import { SubDomainProvider } from 'context';
import Spinner from 'Spinner';

export default function App(props) {
    const [subDomain, setSubDomain] = useState("loading");
    const allowedSubdomains = ["admin", "employee"];
    const afterSubDomainString = ["localhost", process.env.REACT_APP_DOMAIN_URL];

    function checkOrigin(origin) {
        for (const substring of afterSubDomainString) {
            const index = origin.indexOf(substring);
            if (index !== -1) {
                let subdomain = origin.substring(0, index - 1).split('.').pop();
                // Extract the protocol prefix (http:// or https://) if it's present
                const protocolPrefixMatch = origin.match(/^(https?|ftp):\/\//);
                const protocolPrefix = protocolPrefixMatch ? protocolPrefixMatch[0] : '';
                // Remove any protocol prefix (http:// or https://) if it's present
                subdomain = subdomain.replace(/^(https?|ftp):\/\//, '');
                if (allowedSubdomains.includes(subdomain)) {
                    return [subdomain, origin, true];
                } else {
                    const modifiedOrigin = `${protocolPrefix}${origin.substring(index)}`;
                    if (origin === modifiedOrigin || origin.includes('www')) {
                        return ["user", modifiedOrigin, true]
                    }
                    return ["", modifiedOrigin, false];
                }
            }
        }

        return ["", "", false];
    }


    useEffect(() => {
        const result = checkOrigin(window.location.origin)
        // console.log(window.location.origin, result)
        if (result[2]) {
            setSubDomain(result[0])
        } else if ((result[0] === "") && result[1]) {
            window.location.href = result[1]
        }
    }, [])

    return (
        <SubDomainProvider>
            {
                subDomain !== "loading" ?
                    subDomain === "employee" ?
                        <AppEmployee subDomain={subDomain} /> :
                        subDomain === "admin" ?
                            <AppAdmin subDomain={subDomain} /> :
                            subDomain === "user" ?
                                <AppUser subDomain={subDomain} /> :
                                null
                    : <Spinner />
            }
        </SubDomainProvider>
    );
}
