export const backendURL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_DEPLOYED_BACKEND_URL : process.env.REACT_APP_LOCAL_BACKEND_URL

// admin routes
export const assignApplicationToEmployeeRoute = "/admin/assignApplicationToEmployee"
export const getAllApplicationsRoute = "/admin/getAllApplications"
export const getApplicationsOptionsRoute = "/admin/getApplicationsOptions"
export const getAllEmployeesRoute = "/admin/getAllEmployees"
export const getAllUsersRoute = "/admin/getAllUsers"
export const getAllUpdatesRoute = "/admin/getAllUpdates"
export const deleteEmployeeRoute = "/admin/deleteEmployee"
export const changeEmployeePasswordRoute = "/admin/changeEmployeePassword"
export const updateAdminPasswordRoute = "/admin/updateAdminPassword"
export const signInAdminRoute = "/admin/signInAdmin"
export const signUpEmployeeRoute = "/admin/signUpEmployee"

// application routes
export const getUserApplicationRoute = "/application/getUserApplication"
export const createApplicationRoute = "/application/createApplication"

// email routes
export const confirmEmailRoute = (id) => `/email/confirm/${id}`

// employee routes
export const getEmployeeAssignedApplicationsRoute = "/employee/getEmployeeAssignedApplications"
export const createApplicationUpdateRoute = "/employee/createApplicationUpdate"
export const createUserFileRoute = "/employee/createUserFile"
export const updateEmployeeProfileRoute = "/employee/updateEmployeeProfile"
export const updateEmployeePasswordRoute = "/employee/updateEmployeePassword"
export const updateEmployeeInitialPasswordRoute = "/employee/updateEmployeeInitialPassword"
export const signInEmployeeRoute = "/employee/signInEmployee"

// forgot password routes
export const forgotPasswordRoute = "/forgotPassword/"
export const resetPasswordRoute = "/forgotPassword/resetPassword"

// gcloud routes
export const getAllBucketFilesRoute = "/gcloud/listFiles"
export const deleteBucketFileRoute = "/gcloud/deleteFile"
export const storeBucketFileRoute = "/gcloud/storeFile"

// user routes
export const sendVerificationEmailRoute = "/user/sendVerificationEmail"
export const signUpUserRoute = "/user/signup"
export const signInUserRoute = "/user/login"
export const updateUserProfileRoute = "/user/updateUserProfile"
export const updateUserPasswordRoute = "/user/updateUserPassword"
export const feedbackRoute = "/user/feedback"
export const contactUsMessageRoute = "/user/contactUsMessage"