import React, { useContext, useState } from 'react'
import { ProfileContext, SubDomainContext } from 'context'
import { StoreBucketFile } from 'api/gcloud_bucket'
import Spinner from 'Spinner'
import ViewImage from './viewImage'

export default function ProfilePic(props) {

    const { profileData } = useContext(ProfileContext)
    const { subDomain } = useContext(SubDomainContext)
    const [showSpinner, setShowSpinner] = useState(false)
    const [imageKey, setImageKey] = useState(Date.now());
    const [showImage, setShowImg] = useState()

    const gcloudBucketLink = process.env.REACT_APP_GCLOUD_BUCKET_LINK

    const fileCategory = "logo-image"
    const fileName = "profile-logo.png"

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const maxFileSize = 3 * 1024 * 1024; // 3 MB in bytes

        if (file.size > maxFileSize) {
            alert(`Image size must be at most 3 MB.`, 'warning', 5000, 'top-center');
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(`file buffer is: ${reader.result}`);
            if (reader.result) {
                uploadBucketFile(reader.result)
            }
        };



    }

    const uploadBucketFile = async (fileData) => {
        try {
            setShowSpinner(true)
            const result = await StoreBucketFile(profileData.email, fileData, fileName, fileCategory, subDomain)
            // console.log(result)
            if (result.status === 200 && result.message === "File uploaded successfully") {
                setTimeout(() => {
                    setImageKey(prevKey => prevKey + 1);
                }, 5000);
                alert("img changed successfully")
            }

            setShowSpinner(false)
        } catch (err) {
            console.log(err)
            setShowSpinner(false)
        }
    }

    const showImg = (image) => {
        setShowImg(image)
    }

    return (
        <>
            <div className="img-circle text-center mb-3 content_img">
                <img
                    src={`${gcloudBucketLink}${subDomain}/${profileData.email.toLowerCase()}/${fileCategory}/${fileName}?key=${imageKey}`}
                    alt="user profile" className="shadow"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/assets/img/default-profile.png";
                    }}
                    onClick={e => showImg(e.target.src)}
                />
                <div className="middle">
                    <input type="file"
                        id="fileInputImg"
                        onChange={handleFileUpload}
                        accept="image/*"
                        required
                    />
                    <label htmlFor='fileInputImg' className="btn uploadResume" type="button">change</label>
                </div>
            </div>

            {
                showSpinner ? <Spinner /> : null
            }

            {
                showImage ?
                    <ViewImage
                        showImage={showImage}
                        setShowImg={setShowImg}
                    /> : null
            }
        </>
    )
}
