import axios from "./axiosInstance";
import { confirmEmailRoute } from "config";

export const ConfirmEmailAuthentication = async (id) => {
    try {
        const response = await axios.get(confirmEmailRoute(id))
        return response.data
    } catch (err) {
        console.log(err);
    }
}

