import React, { useContext } from 'react'
import { AdminProfileContext } from 'context'
import InfiniteScroll from 'InfiniteScroll';
import { Link } from 'react-router-dom';

export default function Applications(props) {

    const { applications, fetchApplicationData, isLoading, page } = useContext(AdminProfileContext)

    return (
        <>
            <InfiniteScroll fetchDataFunction={fetchApplicationData} isLoading={isLoading} page={page}>
                <div className="row table-responsive">
                    <table className="table table-striped table-hover align-middle">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Application Id</th>
                                <th scope="col">Email</th>
                                <th scope="col">First name</th>
                                <th scope="col">Clients</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                applications.map((item, i) =>
                                    <tr key={item._id}>
                                        <th scope="row">{i + 1}</th>
                                        <th scope="row">{item.applicationId}</th>
                                        <td>{item.personalDetails.email}</td>
                                        <td>{item.personalDetails.firstName}</td>
                                        <td>
                                            <Link className='viewApplication btn'
                                                to={`/myClients/${item.applicationId}`}
                                                state={{ application: item }}
                                                style={{ background: "rgb(210, 105, 30)", color: "white" }}
                                            >
                                                View client
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>

                </div>
            </InfiniteScroll>
        </>
    )
}
