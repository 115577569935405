import { ProfileContext } from 'context'
import React, { useContext, useState } from 'react'
import IntlTelInput from 'react-intl-tel-input'

export default function InputTel({ mobileNumber, setMobileNumber }) {

    const {geoIpCountry} = useContext(ProfileContext)

    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)

    const handlePhoneNumberChange = (status, phoneNumber, obj) => {
        // console.log(status, phoneNumber, obj)
        setMobileNumber(prevValues => ({
            ...prevValues,
            countryCode: obj.iso2,
            dialCode: obj.dialCode,
            status,
            phoneNumber
        }));
        if (phoneNumber) {
            if (status) {
                setIsValidPhoneNumber(true)
            } else {
                setIsValidPhoneNumber(false)
            }
        } else {
            setIsValidPhoneNumber(true)
        }
    }

    const handleSelectFlag = async (currentNumber, seletedCountryData, fullNumber, isValid) => {
        // console.log({ currentNumber, seletedCountryData, fullNumber, isValid })
        setMobileNumber(prevValues => ({
            ...prevValues,
            countryCode: seletedCountryData.iso2,
            dialCode: seletedCountryData.dialCode,
            status: isValid
        }));

        if (isValid) {
            setIsValidPhoneNumber(true)
        } else {
            setIsValidPhoneNumber(false)
        }
    }

    return (
        <>

            <div className="phone">
                <IntlTelInput 
                    inputClassName={"input100"}
                    preferredCountries={['us', 'in', 'gb',]}
                    value={mobileNumber.phoneNumber || ''}
                    placeholder={""}
                    type={"number"}
                    format={true}
                    onPhoneNumberChange={handlePhoneNumberChange}
                    autoHideDialCode={true}
                    onSelectFlag={handleSelectFlag}
                    geoIpLookup={""}
                    defaultCountry={mobileNumber.countryCode || geoIpCountry || ''}
                />
                {
                    mobileNumber.phoneNumber ?
                        isValidPhoneNumber ?
                            <i className={`bi bi-check-circle text-center mr-1 success`} /> :
                            <i className={`bi bi-x-circle text-center mr-1 fail`} /> :
                        null
                }
            </div>
        </>
    )
}
