import React, { useContext, useMemo } from 'react'
import { SubDomainContext, ProfileContext } from 'context'
import ProfilePic from './profilePic'

export default function NavTabs(props) {

    const tabLinksAll = ["Account", "Password", "Resume", "Cover letter"]
    const tabIcons = ["person", "key", "file-earmark-person", "card-text"]

    const { subDomain } = useContext(SubDomainContext)
    const { profileData } = useContext(ProfileContext)

    const tabLinks = useMemo(() => {
        if (subDomain === "employee" || subDomain === "admin") {
            // Filter from tabLinksAll to only include the first two items
            return tabLinksAll.filter((_, index) => index < 2);
        } else {
            return tabLinksAll;
        }

    }, [subDomain]);

    return (
        <div className="profile-tab-nav border-right">
            <div className="p-4">
                <ProfilePic />
                <h4 className="text-center userProfileName">{profileData.name}</h4>
            </div>
            <div className="nav flex-column nav-pills" id="v-pills-tab">
                {
                    tabLinks.map((tabLink, index) => <NavTabElement tabLink={tabLink} icon={tabIcons[index]} key={tabLink} />)
                }
            </div>
        </div>
    )
}

const NavTabElement = ({ tabLink, icon }) => {

    const { navTab, setNavTab } = useContext(ProfileContext)

    return (
        <div className={`nav-link ${navTab === tabLink ? 'active' : ''}`} onClick={() => setNavTab(tabLink)}>
            <i className={`bi bi-${icon} text-center mr-1`} />
            {tabLink}
        </div>
    )
}