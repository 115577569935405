import React from 'react'

export default function Spinner() {


    return (
        <>
            <div id="preloader" className='spinner'></div>
        </>
    )
}
