import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import ScrollToTop from 'ScrollTotop'
import Header from 'Header/user'
import Loader from 'Loader'
import Footer from 'Footer'
import Home from 'Home'
import LogIn from 'LogIn/user'
import Profile from 'MyProfile'
import MyApplications from 'MyApplications/user'
import Updates from 'Updates'
import { SubDomainContext, ProfileProvider, ApplicationProvider, UpdatesProvider } from 'context'
import EmailAuthentication from 'EmailAuthentication'
import ForgotPassword from 'ForgotPassword'

export default function App({ subDomain }) {

    const { setSubDomain } = useContext(SubDomainContext)

    useEffect(() => {
        setSubDomain(subDomain)
    }, [subDomain, setSubDomain])

    return (
        <>
            <div className='app-user' style={{ minHeight: "100vh" }}>
                <BrowserRouter>
                    <ProfileProvider>
                        <ApplicationProvider>
                            <UpdatesProvider>
                                <Loader />
                                <ScrollToTop />
                                <Header />
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/login" element={<LogIn />} />
                                    <Route path="/profile" element={<Profile />} />
                                    <Route path="/email-authentication/:id" element={<EmailAuthentication />} />
                                    <Route path="/myApplications" element={<MyApplications />} />
                                    <Route path="/updates" element={<Updates />} />
                                    <Route path="/forgotPassword/:token" element={<ForgotPassword />} />
                                    <Route path="*" element={<Navigate to="/" />} />
                                </Routes>
                            </UpdatesProvider>
                        </ApplicationProvider>
                    </ProfileProvider>
                </BrowserRouter>
            </div>
            <Footer />
        </>
    )
}
