import React from 'react'
import './news.css'

export default function News(props) {


    return (
        <>
            <div className="container news">
                <div className="ticker">
                    <h5 className="title">
                        Student Offer
                    </h5>
                    <marquee>
                        <p className="news-content">
                        🎓 Students, Save Big: Exclusive 10% Discount – Act Fast, Limited Time Offer! 🏷️
                        </p>
                    </marquee>
                </div>
            </div>
        </>
    )
}
