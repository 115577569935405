import React from 'react'
import { Link } from 'react-router-dom'

export default function Success(props) {

    return (
        <>
            <div className='container'>
                <div className='row align-items-center' style={{ height: "100vh", justifyContent: "center" }}>

                    <div className='row align-items-center' style={{ backgroundColor: "rgba(0,0,0,0.6)", height: "50vh" }}>
                        {

                            <h1 className='text-center' style={{ color: "white" }}>
                                You have successfully changed password, click here to
                                <Link to="/login" style={{ color: "#ffc107" }}> login</Link>
                            </h1>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
