import React, { useContext } from 'react'
import Dropdown from './dropdown'
import Applications from './applications'
import { AdminProfileContext } from 'context'

export default function Main() {

    const { sideBarActive } = useContext(AdminProfileContext)

    return (
        <>

            <div className={`p-4 p-md-5 pt-5 content ${sideBarActive ? 'active' : ''}`}>
                <Dropdown />
                <Applications />
            </div>
        </>
    )
}
