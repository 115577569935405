import { setAccessToken } from 'api/axiosInstance';
import React, { useState, useCallback, createContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export const profileContext = createContext();

export default function Profile({ children }) {

    const navigate = useNavigate()

    const [navTab, setNavTab] = useState('Account')
    const [resumeFile, setResumeFile] = useState()
    const [coverLetterFile, setCoverLetterFile] = useState()
    const [logInProfileData, setLogInProfileData] = useState()
    const [profileData, setProfileData] = useState()
    const [editProfile, setEditProfile] = useState(false)
    const [name, setName] = useState('')
    const [geoIpCountry, setGeoIpCountry] = useState()
    const [mobileNumber, setMobileNumber] = useState()
    const [page, setPage] = useState(0)
    const [token, setToken] = useState()

    const limit = 100;

    useEffect(() => {

        const getGeoIpCountry = async () => {
            var data = await fetch("https://ipapi.co/json")
            data = await data.json()
            // console.log(data.country_code)
            setGeoIpCountry(data.country_code.toLowerCase())
            setMobileNumber(prev => ({
                ...prev,
                countryCode: profileData?.mobileNumber?.countryCode || data.country_code.toLowerCase()
            }));
        }

        if (!geoIpCountry) {
            getGeoIpCountry()
        }

    }, [geoIpCountry])

    useEffect(() => {
        resetProfile()
    }, [profileData])

    useEffect(() => {
        setAccessToken(token)
    }, [token])

    const resetProfile = async () => {
        setNavTab('Account')
        setEditProfile(false)
        setMobileNumber(prev => ({
            ...prev,
            phoneNumber: profileData?.mobileNumber?.phoneNumber?.toString() || "",
            countryCode: profileData?.mobileNumber?.countryCode || geoIpCountry,
            dialCode: profileData?.mobileNumber?.dialCode || "",
            status: true
        }));
        setName(profileData?.name || "")
    }

    const onLoginStart = useCallback(() => {
        // console.log("login start");
    }, []);

    const onLogoutFailure = useCallback(() => {
        // console.log("logout fail");
    }, []);

    const onLogout = async () => {
        setNavTab('Account')
        setResumeFile()
        setCoverLetterFile()
        setLogInProfileData()
        setProfileData()
        setEditProfile(false)
        setName()
        setMobileNumber({
            phoneNumber: "",
            countryCode: geoIpCountry,
            dialCode: "",
            status: true
        });
        setPage(0)
        setAccessToken()
        // console.log("logout success");
        navigate('/login')
    };

    const providerItem = {
        navTab, setNavTab,
        geoIpCountry,
        resumeFile, setResumeFile,
        coverLetterFile, setCoverLetterFile,
        logInProfileData, setLogInProfileData,
        profileData, setProfileData,
        onLoginStart, onLogoutFailure, onLogout,
        editProfile, setEditProfile,
        name, setName,
        mobileNumber, setMobileNumber,
        resetProfile, page, setPage,
        limit,
        token, setToken
    };

    return (
        <profileContext.Provider value={providerItem}>
            {children}
        </profileContext.Provider>
    )
}
