import React from 'react'

export default function ViewImage({ showImage, setShowImg }) {


    return (
        <>
            {
                showImage ?
                    <div id="viewPDF">
                        <div className='closePDF' onClick={() => setShowImg(null)}>
                            <i className="bi bi-x-circle"></i>
                        </div>
                        <div className='d-flex align-items-center justify-content-center align-middle' style={{height: "90vh"}}>
                            <img src={showImage} alt={showImage} />
                        </div>
                    </div> : null
            }
        </>
    )
}
