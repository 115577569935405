import React, { useEffect, useState } from 'react'
import { SignUpUser } from 'api/user'
import Spinner from 'Spinner'

export default function SignupForm({ setConfirmationEmailSent }) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [passwordType, setPasswordType] = useState("password");

    useEffect(() => {
        if (password && confirmPassword) {
            setPasswordError(password !== confirmPassword);
        } else {
            setPasswordError(false);
        }
    }, [password, confirmPassword])

    useEffect(() => {
        if (emailError) {
            setEmailError(false)
        }
    }, [email])

    const handleOnSubmit = async (e) => {
        try {
            e.preventDefault();
            if (passwordError) return;

            setEmailError(false)
            setConfirmationEmailSent(false)

            setShowLoader(true)

            const result = await SignUpUser(name, email, password)

            setShowLoader(false)

            // console.log(result)

            if ((result.status === 400) && (result.message === "email already exists")) {
                setEmailError(true)
            } else if ((result.status === 200) && (result.message === "user account created successfully")) {
                setConfirmationEmailSent(true)
            }
        } catch (err) {
            console.log(err)
            setShowLoader(false)
        }
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }


    return (
        <>
            <div className="sign-up" id="sign-up-info">
                <h1>Create Account</h1>
                <p className="small">use your email for registration:</p>
                <form id="sign-up-form" onSubmit={handleOnSubmit}>
                    <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                    <input type="email" placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value); setEmailError(false) }} required />
                    {
                        emailError ? <h6 style={{ color: "red" }}>Email already exists</h6> : null
                    }

                    <div className='password-input'>
                        <input type={passwordType} placeholder="Password" value={password}
                            onChange={(e) => setPassword(e.target.value)} required />

                        <div className='password-eye-icon' onClick={togglePassword}
                            style={{ paddingRight: "145px" }}
                        >
                            {passwordType === "password" ? <i className="bi bi-eye-slash" /> : <i className="bi bi-eye" />}
                        </div>
                    </div>

                    <div className='password-input'>

                        <input type={passwordType} placeholder="Confirm Password" value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} required />

                        <div className='password-eye-icon' onClick={togglePassword}
                            style={{ paddingRight: "145px" }}
                        >
                            {passwordType === "password" ? <i className="bi bi-eye-slash" /> : <i className="bi bi-eye" />}
                        </div>
                    </div>

                    {
                        passwordError ? <h6 style={{ color: "red" }}>Passwords do not match</h6> : null
                    }
                    <button className="control-button up" type='submit'>Sign Up</button>
                </form>
            </div>
            {
                showLoader ? <Spinner /> : null
            }
        </>
    )
}
