import React from 'react'

export default function CreateUploadFile({ isDisabled, question, fileAction, setFileActionResume, setFileActionCoverLetter }) {

    const handleFileAction = (e) => {
        if (question.id === "resume") {
            setFileActionResume(e.target.value)
        } else if (question.id === "coverLetter") {
            setFileActionCoverLetter(e.target.value)
        }
    }


    return (
        <div className='col-md-5'>
            <label htmlFor={question.id} className="form-label" style={{ color: "white" }}>{question.label}
                {
                    question.id !== "coverLetter" ? <span style={{ color: "red" }}> *</span> : null
                }
            </label>
            <select id={question.id} className="form-select"
                required={question.id !== "coverLetter"}
                style={{ marginRight: "10px" }}
                name={question.id}
                onChange={handleFileAction}
                value={fileAction}
                disabled={isDisabled}
            >
                <option value="">Choose...</option>
                <option value={`upload-${question.id}`}>Upload</option>
                <option value={`create-${question.id}`}>Create</option>
            </select>
        </div>
    )
}
