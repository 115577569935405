import React from 'react'
import { CountryDropdown } from 'react-country-region-selector';

export default function InputCountry({ application, question, navTab, formValues, setFormValues }) {

    const handleInputChange = (id, value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [navTab]: {
                ...formValues[navTab],
                [id]: value
            }
        }));
    };

    return (
        <>
            <div className="col-md-4">
                <label htmlFor={question.id} className="form-label" style={{ color: "white" }}>{question.label}
                    {
                        question.required ? <span style={{ color: "red" }}> *</span> : null
                    }
                </label>
                <CountryDropdown
                    className="form-select" id={question.id} required={question.required}
                    priorityOptions={[ "US", "IN", "CA", "GB"]}
                    value={application?.[navTab]?.[question.id] || formValues[navTab]?.[question.id] || ''}
                    onChange={(value) => handleInputChange(question.id, value)}
                    disabled={application ? true : false}
                />
            </div>
        </>
    )
}
