import Spinner from 'Spinner'
import { ConfirmEmailAuthentication } from 'api/email'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

export default function EmailAuthentication(props) {

    const { id } = useParams()
    const [isSpinning, setIsSpinning] = useState(false)
    const [message, setMessage] = useState()

    useEffect(() => {
        if (id && !isSpinning) {
            setIsSpinning(true);
        }
    }, [id])

    useEffect(() => {
        const confirmEmail = async () => {
            const result = await ConfirmEmailAuthentication(id);
            // console.log(result)
            if (result.msg === "Your email is confirmed!" || result.msg === "Your email was already confirmed!") {
                setIsSpinning(false)
                setMessage(result.msg)
            }
        }

        if (isSpinning) {
            confirmEmail()
        }
    }, [isSpinning])


    return (
        <>
            <div className='container'>
                <div className='row align-items-center' style={{ height: "100vh", justifyContent: "center" }}>

                    <div className='row align-items-center' style={{ backgroundColor: "rgba(0,0,0,0.6)", height: "50vh" }}>
                        {
                            isSpinning ?
                                <>
                                    <Spinner />
                                    <h1 className='text-center' style={{ color: "white" }}> Please wait while we confirm your email</h1> :
                                </> :
                                <h1 className='text-center' style={{ color: "white" }}>
                                    {message} click here to
                                    <Link to="/login" style={{ color: "#ffc107" }}> login</Link>
                                </h1>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
