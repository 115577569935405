const personalDetails = [
    {
        id: 'email',
        label: 'Email',
        type: 'email',
        required: true
    },
    {
        id: 'firstName',
        label: 'First name',
        type: 'text',
        required: true
    },
    {
        id: 'middleName',
        label: 'Middle name',
        type: 'text',
        required: false
    },
    {
        id: 'lastName',
        label: 'Last name',
        type: 'text',
        required: false
    },
    {
        id: 'gender',
        label: 'Gender',
        type: 'select',
        options: ['MALE', 'FEMALE', 'OTHER'],
        required: true
    },
    {
        id: 'dateOfBirth',
        label: 'Date of birth',
        type: 'date',
        required: true
    },
    {
        id: 'anyDisability',
        label: 'Any disability',
        type: 'select',
        options: ['YES', 'NO'],
        required: true
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
        required: true
    },
    {
        id: 'countryOfBirth',
        label: 'Country of birth',
        type: 'country',
        required: true
    },
    {
        id: 'countryOfCitizenShip',
        label: 'Country of citizenship',
        type: 'country',
        required: true
    },
    {
        id: 'mobileNumber',
        label: 'Mobile number',
        type: 'tel',
        required: true
    },
    {
        id: 'linkedInUrl',
        label: 'Linked in',
        type: 'text',
        required: false
    },
    {
        id: 'resume',
        label: 'Resume',
        type: 'file',
        accept: "application/pdf",
        required: false
    },
    {
        id: 'coverLetter',
        label: 'Cover letter',
        type: 'file',
        accept: "application/pdf",
        required: false
    },
    {
        id: 'resumeImage',
        label: 'Resume image',
        type: 'file',
        accept: "image/*",
        required: false
    }
]

const EducationDetails = [
    {
        id: 'fieldOfStudy',
        label: 'Latest degree',
        type: 'text',
        required: true
    },
    {
        id: 'startDate',
        label: 'Start date',
        type: 'date',
        required: true
    },
    {
        id: 'graduationDate',
        label: 'Graduation date',
        type: 'date',
        required: false
    },
    {
        id: 'universityName',
        label: 'University name',
        type: 'text',
        required: true
    },
    {
        id: 'country',
        label: 'Country',
        type: 'country',
        required: true
    },
    {
        id: 'state',
        label: 'State',
        type: 'state',
        required: true
    },
    {
        id: 'city',
        label: 'City',
        type: 'text',
        required: true
    },
    {
        id: 'certifications',
        label: 'Certifications',
        type: 'text',
        required: false
    },
    {
        id: 'GPA',
        label: 'GPA',
        type: 'text',
        required: true
    },
]

const EmploymentDetails = [
    {
        id: 'preferredJobTitles',
        label: 'Preferred Job Titles',
        type: 'text',
        required: false
    },
    {
        id: 'skills',
        label: 'Skills',
        type: 'text',
        required: false
    },
    {
        id: 'languagesKnown',
        label: 'Programming Languages known',
        type: 'text',
        required: false
    },
    {
        id: 'jobType',
        label: 'Job type',
        type: 'select',
        options: ['FULL_TIME', 'CONTRACT', 'INTERNSHIP'],
        multiple: true,
        required: false
    },
    {
        id: 'salaryExpectation',
        label: 'Salary expectation (Per Annum / Per Hr)',
        type: 'text',
        required: false
    },
    {
        id: 'canRelocateToUSA',
        label: 'Willing to relocate to USA',
        type: 'select',
        options: ['YES', 'NO'],
        required: false
    },
    {
        id: 'areaOfInterest',
        label: 'Area of interest',
        type: 'text',
        required: false
    },
    {
        id: 'currentlyWorking',
        label: 'Are you currently working ?',
        type: 'select',
        options: ['YES', 'NO'],
        required: false
    },
    {
        id: 'openToRemote',
        label: 'Open to remote',
        type: 'select',
        options: ['YES', 'NO'],
        required: false
    },
    {
        id: 'noticePeriod',
        label: 'Latest date of availability',
        type: 'text',
        required: false
    },
    {
        id: 'totalExperience',
        label: 'Your total experience',
        type: 'text',
        required: false
    },
    {
        id: 'totalUSAExperience',
        label: 'Your total USA experience',
        type: 'text',
        required: false
    },
    {
        id: 'availableDateTimeForInterview',
        label: 'Available Dates and Time For Interview',
        type: 'text',
        required: false
    },
]

const WorkVisaStatusDetails = [
    {
        id: 'requiredSponsorship',
        label: 'Require sponsorship',
        type: 'select',
        options: ['YES', 'NO'],
        required: true
    },
    {
        id: 'visaStatus',
        label: 'Visa status',
        type: 'text',
        required: false
    },
    {
        id: 'currentClearanceLevel',
        label: 'Current clearance level',
        type: 'text',
        required: false
    },
    {
        id: 'currentStatus',
        label: 'currentStatus',
        type: 'select',
        options: ['F1', 'H1', 'L1'],
        required: false
    },
    {
        id: 'validityDate',
        label: 'Validity date',
        type: 'date',
        required: false
    },
    {
        id: 'endDate',
        label: 'End date',
        type: 'date',
        required: false
    },
]

export const questions = {
    "personalDetails": personalDetails,
    "education": EducationDetails,
    "employmentDetails": EmploymentDetails,
    "workVisaStatus": WorkVisaStatusDetails,
}