import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import NavTabs from './nav-tabs'
import Account from './account'

export default function Index(props) {

    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        if (!location?.state?.employee) {
            navigate('/login')
        }
    }, [location])

    return (
        location?.state?.employee ?
            <div className='myProfile'>
                <section>
                    <div className="container-fluid">
                        <div className="bg-white shadow rounded-lg d-block d-sm-flex mt-5">
                            <NavTabs profileData={location.state.employee} />
                            <div className="tab-content p-4 p-md-5" id="v-pills-tabContent">
                                <Account profileData={location.state.employee} />
                            </div>
                        </div>
                    </div>
                </section>
            </div> : null
    )
}
