import React, { useContext, useEffect } from 'react'
import './myApplications.css'
import SideBar from './sideBar'
import Main from './main'
import { ProfileContext } from 'context'
import { useNavigate } from 'react-router-dom'

export default function Index(props) {

    const { profileData } = useContext(ProfileContext)

    const navigate = useNavigate()

    useEffect(() => {
        if (!profileData) {
            navigate('/login')
        }
    }, [profileData, navigate])

    return (
        <>
            <div className='myApplications' style={{ marginTop: "70px" }}>
                <div className="wrapper d-flex align-items-stretch">
                    <SideBar />
                    <Main />
                </div>
            </div>
        </>
    )
} 
