import React, { useState, createContext, useEffect, useContext } from 'react'
import { ProfileContext, } from 'context';
import { GetAllEmployees, DeleteEmployee } from 'api/admin';

export const employeeDataContext = createContext();

export default function EmployeeData({ children }) {

    const { profileData } = useContext(ProfileContext)
    const [page, setPage] = useState(0)
    const [sideBarActive, setSideBarActive] = useState()
    const [filters, setFilters] = useState({ sortBy: "totalApplicationsAssigned" ,sortDirection: "asc" })
    const [employees, setEmployees] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const limit = 100;

    useEffect(() => {
        setEmployees([])
        setPage(0)
    }, [JSON.stringify(filters)])

    useEffect(() => {
        if(!profileData){
            setPage(0)
            setSideBarActive()
            setFilters({ sortBy: "totalApplicationsAssigned" ,sortDirection: "asc" })
            setEmployees([])
            setIsLoading(false)
        }
    }, [profileData])

    const fetchAllEmployees = async () => {
        try {
            if (page === null || isLoading || !profileData) return;
            setIsLoading(true)
            const result = await GetAllEmployees(filters, page, limit)
            // console.log(result)
            if (result?.status === 200 && result?.data) {
                const newEmployees = result.data.employees.filter(newEmp => {
                    // Check if the newEmp is not already in employees
                    return !employees.some(existingApp => existingApp._id === newEmp._id);
                });

                setEmployees(prev => [...prev, ...newEmployees]);
                if (result.data.nextPage) {
                    setPage(prev => prev + 1)
                } else {
                    setPage(null)
                }
            } else {
                setPage(null)
            }
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const removeEmployee = async (employeeId) => {
        try{
            const result = await DeleteEmployee(employeeId)
            // console.log(result)
            if(result.status === 200){
                const updatedEmployees = employees.filter(employee => employee.employeeId !== employeeId);
                setEmployees(updatedEmployees)
            }
        } catch(err) {
            console.log(err)
        }
    }

    const providerItem = {
        page, setPage,
        limit,
        sideBarActive, setSideBarActive,
        filters, setFilters,
        employees, setEmployees, isLoading,
        fetchAllEmployees, removeEmployee
    };

    return (
        <employeeDataContext.Provider value={providerItem}>
            {children}
        </employeeDataContext.Provider>
    )
}
