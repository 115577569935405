import React from 'react'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

export default function Account({ profileData }) {

    return (
        <>
            <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                <h3 className="mb-4">Employee Profile</h3>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text"
                                className="form-control"
                                value={profileData.name}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email"
                                className="form-control"
                                defaultValue={profileData.email}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label>Phone number</label>
                        <div className="form-group phone">
                            <IntlTelInput
                                preferredCountries={['us', 'in', 'gb',]}
                                inputClassName={"form-control"}
                                value={(profileData?.mobileNumber?.phoneNumber.toString()) || ''}
                                placeholder={"Enter Your Number"}
                                type={"tel"}
                                format={true}
                                disabled={true}
                                autoHideDialCode={true}
                                geoIpLookup={""}
                                defaultCountry={profileData?.mobileNumber?.countryCode || ''}
                            />
                            {
                                profileData?.mobileNumber?.phoneNumber ?
                                    profileData?.mobileNumber?.status ?
                                        <i className={`bi bi-check-circle text-center mr-1 success`} /> :
                                        <i className={`bi bi-x-circle text-center mr-1 fail`} /> :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
