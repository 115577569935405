import React, { useState, useContext } from 'react'
import { ForgotPassword } from 'api/forgotPassword'
import AlertBox from 'AlertBox'
import Spinner from 'Spinner'
import { SubDomainContext } from 'context'

export default function SendForgotPassword(props) {

    const { subDomain } = useContext(SubDomainContext)
    const [email, setEmail] = useState("")
    const [showAlert, setShowAlert] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleForgotPassword = async () => {
        try {
            if (!email) {
                setShowAlert("All fields are required")
                return;
            }
            setLoading(true)
  
            const result = await ForgotPassword(subDomain, email)
            setLoading(false)
            if (result) {
                setShowAlert(result.message)
            }
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" id="sendForgotPassword" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Forgot password</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row mb-3">
                                    <label htmlFor="ApplicationId" className="form-label">Registered Email id</label>
                                    <div>
                                        <input type="email" className="form-control" id="email"
                                            style={{ border: "0.05rem solid black" }}
                                            value={email}
                                            placeholder={"type your registered email id"}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary"
                                onClick={handleForgotPassword}
                            >Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <AlertBox
                showAlert={showAlert}
                setShowAlert={setShowAlert}
            />
            {
                loading ? <Spinner /> : null
            }
        </>
    )
}
