import Profile, { profileContext } from './profile'
import Application, { applicationContext } from './application'
import AdminProfile, { adminProfileContext } from './adminProfile'
import SubDomain, { subDomainContext } from './subDomain'
import EmployeeData, { employeeDataContext } from './employeeData'
import Updates, { updatesContext } from './updates'

export const ProfileProvider = Profile
export const ProfileContext = profileContext

export const ApplicationProvider = Application
export const ApplicationContext = applicationContext

export const AdminProfileProvider = AdminProfile
export const AdminProfileContext = adminProfileContext

export const SubDomainProvider = SubDomain
export const SubDomainContext = subDomainContext

export const EmployeeDataProvider = EmployeeData
export const EmployeeDataContext = employeeDataContext

export const UpdatesProvider = Updates
export const UpdatesContext = updatesContext