import React, { useContext, useEffect, useState } from 'react'
import './login.css'
import { useNavigate } from 'react-router-dom'
import { SubDomainContext, ProfileContext } from 'context'
import { SignInEmployee } from 'api/employee'
import { SignInAdmin } from 'api/admin'
import Spinner from 'Spinner'
import SendForgotPassword from 'LogIn/modal/forgotPassword'

export default function Index() {

    const navigate = useNavigate()

    const { subDomain } = useContext(SubDomainContext)
    const { profileData, setProfileData, setToken } = useContext(ProfileContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const [passwordType, setPasswordType] = useState("password");
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (profileData) {
            navigate('/myClients')
        }
    }, [profileData, navigate])

    useEffect(() => {
        setError(false)
    }, [email, password])

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setError(false)
            if (!email || !password) return
            setLoading(true)
            if (subDomain === "employee") {
                const result = await SignInEmployee(email, password)
                // console.log(result)
                if (result && result.status === 200 && result.employee) {
                    setProfileData(result.employee)
                    setToken(result.token)
                } else {
                    setError(true)
                }
            } else if (subDomain === "admin") {
                const result = await SignInAdmin(email, password)
                // console.log(result)
                if (result && result.status === 200 && result.admin) {
                    setProfileData(result.admin)
                    setToken(result.token)
                } else {
                    setError(true)
                }
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }



    return (
        <>
            <div className='app-management-login'>
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <div className="login100-form-title">
                                <span className="login100-form-title-1">
                                    Sign In
                                </span>
                            </div>

                            <form className="login100-form validate-form" onSubmit={handleSubmit}>
                                <div className="wrap-input100 validate-input m-b-26">
                                    <span className="label-input100">Email</span>
                                    <input className="input100" type="email" name="username" placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <span className="focus-input100"></span>
                                </div>

                                <div className="wrap-input100 validate-input m-b-18 password-input">
                                    <span className="label-input100">Password</span>
                                    <input className="input100" name="pass" placeholder="Enter password"
                                        type={passwordType}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <div className='password-eye-icon' onClick={togglePassword}
                                    // style={{ paddingRight: "30px" }}
                                    >
                                        {passwordType === "password" ? <i className="bi bi-eye-slash" /> : <i className="bi bi-eye" />}
                                    </div>
                                    <span className="focus-input100"></span>
                                </div>

                                <div className="container-login100-form-btn">
                                    {
                                        error ?
                                            <>
                                                <br />
                                                <p style={{ color: "red" }}>invalid details</p>
                                            </>
                                            : null
                                    }
                                    <br />
                                    <p className="forgot-password" data-bs-toggle="modal" data-bs-target="#sendForgotPassword">Forgot your password?</p>
                                    <button className="login100-form-btn" type='submit'>
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <SendForgotPassword />
            {
                loading ? <Spinner /> : null
            }
        </>
    )
}
