import { ProfileContext } from 'context'
import React, { useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function Header(props) {

    const { pathname } = useLocation();
    const { profileData, onLogout } = useContext(ProfileContext)

    const [showMenu, setShowMenu] = useState(false)

    const [link1, setLink1] = useState(false)
    const [link2, setLink2] = useState(false)
    const [link3, setLink3] = useState(false)
    const [link4, setLink4] = useState(false)
    const [link5, setLink5] = useState(false)
    const [link6, setLink6] = useState(false)
    const [link7, setLink7] = useState(false)

    const setLink = (link) => {
        setLink1(false)
        setLink2(false)
        setLink3(false)
        setLink4(false)
        setLink5(false)
        setLink6(false)
        setLink7(false)
        if (link === "Home") { setLink1(true) }
        if (link === "About") { setLink2(true) }
        if (link === "Services") { setLink3(true) }
        if (link === "Pricing") { setLink4(true) }
        if (link === "Contact Us") { setLink5(true) }
        if (link === "Updates") { setLink6(true) }
        if (link === "My Clients") { setLink7(true) }
    }

    return (
        <>
            <header id="header" className={`fixed-top ${pathname !== '/' ? 'headerBackground' : ''}`}>
                <div className="container d-flex align-items-center justify-content-lg-between">

                    <h1 className="logo me-auto me-lg-0">
                        <Link to="/">
                            JH<span>.</span>
                        </Link>
                    </h1>

                    {/* <a href="index.html" className="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a> */}

                    <nav id="navbar" className={`navbar order-last order-lg-0 ${showMenu ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <HeaderLinkComponent link={link1} to={"/#hero"} setLink={setLink} linkName={"Home"} />
                            <HeaderLinkComponent link={link2} to={"/#about"} setLink={setLink} linkName={"About"} />
                            <HeaderLinkComponent link={link3} to={"/#services"} setLink={setLink} linkName={"Services"} />
                            <HeaderLinkComponent link={link4} to={"/#pricing"} setLink={setLink} linkName={"Pricing"} />
                            <HeaderLinkComponent link={link5} to={"/#contact"} setLink={setLink} linkName={"Contact Us"} />
                            {
                                profileData ?
                                    <>
                                        <HeaderLinkComponent link={link6} to={"/updates"} setLink={setLink} linkName={"Updates"} />
                                        <HeaderLinkComponent link={link7} to={"/myClients"} setLink={setLink} linkName={"My Clients"} />
                                    </>
                                    : null
                            }
                        </ul>
                        <i className={`bi mobile-nav-toggle ${showMenu ? 'bi-x' : 'bi-list'}`} onClick={() => setShowMenu(prev => !prev)}></i>
                    </nav>

                    {
                        !profileData ?
                            <Link to="/login" className="get-started-btn scrollto">Sign in</Link> :

                            <div className='navbar right order-last order-lg-0'>
                                <ul>
                                    <li className="dropdown">
                                        <img width="35" height="35" className='user profile'
                                            src="https://cdn-icons-png.flaticon.com/512/9814/9814497.png"
                                            alt="user-profile-icon"
                                        />
                                        <i className="bi bi-chevron-down" />
                                        <ul>

                                            <li><Link to="/profile">My Profile</Link></li>
                                            <li><a onClick={onLogout}>Logout</a></li>

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                    }

                </div>
            </header>
        </>
    )
}


const HeaderLinkComponent = ({ link, to, setLink, linkName }) => {

    const { pathname } = useLocation();

    return (
        <>
            <li>
                <Link className={`nav-link scrollto ${(
                    (pathname === '/' || pathname === '/updates' || pathname === "/myClients") && link) ? 'active' : ''}`}
                    to={to}
                    onClick={() => setLink(linkName)}
                >
                    {linkName}
                </Link>
            </li>
        </>
    )
}