import { useState } from 'react';
import UploadCoverLetter from './upload-cover-letter'
import ViewCoverLetter from './view-cover-letter'

export default function Index(props) {

    const [fileKey, setFileKey] = useState(Date.now());

    return (
        <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* <UploadCoverLetter setFileKey={setFileKey} /> */}
            <ViewCoverLetter fileKey={fileKey} setFileKey={setFileKey} />
        </div>
    )
}
