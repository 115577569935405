import React, { useState, useContext, useEffect } from 'react'
import { SubDomainContext } from 'context'
import { UpdateUserPassword } from 'api/user'
import { UpdateEmployeePassword } from 'api/employee'
import { UpdateAdminPassword } from 'api/admin'

export default function Password(props) {

    const { subDomain } = useContext(SubDomainContext)

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [editPassword, setEditPassword] = useState(false)
    const [passwordMismatchError, setPasswordMismatchError] = useState(true)
    const [oldPasswordError, setOldPasswordError] = useState(false)
    const [updateSuccessful, setUpdateSuccessful] = useState(false)

    useEffect(() => {
        if(oldPasswordError){
            setOldPasswordError(false)
        }
    }, [oldPassword])

    useEffect(() => {
        if (updateSuccessful) {
            setTimeout(() => {
                setUpdateSuccessful(false)
            }, 5000);
        }
    }, [updateSuccessful])

    useEffect(() => {
        if (confirmPassword && newPassword) {
            setPasswordMismatchError(confirmPassword !== newPassword)
        } else {
            setPasswordMismatchError(false)
        }
    }, [confirmPassword, newPassword])

    const changeUserPassword = async () => {

        if (!newPassword || newPassword !== confirmPassword) return;

        var result

        if (subDomain === "employee") {
            result = await UpdateEmployeePassword(oldPassword, newPassword, false)
        } else if (subDomain === "user") {
            result = await UpdateUserPassword(oldPassword, newPassword)
        } else if (subDomain === "admin") {
            result = await UpdateAdminPassword(oldPassword, newPassword)
        }
        // console.log(result)
        if (result.status === 400) {
            if (result.message === "incorrect old password") {
                setOldPasswordError(true)
            }
        } else if (result.status === 200) {
            if (result.message === "password updated successfully") {
                setUpdateSuccessful(true);
                resetPasswords();
            }
        }

    }

    const resetPasswords = async () => {
        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setEditPassword(false)
        setPasswordMismatchError(false)
        setOldPasswordError(false)
    }


    return (
        <div className="tab-pane fade show active">
            <h3 className="mb-4">Password Settings</h3>
            <div className="row">

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Old password</label>
                        <input
                            type="password"
                            className="form-control"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            disabled={!editPassword}
                        />
                    </div>
                    {
                        oldPasswordError ?
                            <h5 style={{ color: "red" }}>incorrect old password</h5> : null
                    }
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>New password</label>
                        <input
                            type="password"
                            className="form-control"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            disabled={!editPassword}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Confirm new password</label>
                        <input
                            type="password"
                            className="form-control"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={!editPassword}
                        />
                    </div>
                </div>
                {
                    passwordMismatchError ?
                        <h5 style={{ color: "red" }}>Passwords do not match</h5> : null
                }
                {
                    updateSuccessful ?
                        <h5 style={{ color: "green" }}>Password updated successfully</h5> : null
                }
            </div>
            {
                editPassword ?
                    <div className='updateBtn'>
                        <button className="btn btn-primary update updateCancel"
                            onClick={changeUserPassword}
                            disabled={passwordMismatchError || !newPassword || !confirmPassword}
                        >
                            Update
                        </button>
                        <button className="btn btn-light updateCancel"
                            onClick={resetPasswords}
                        >
                            Cancel
                        </button>
                    </div> :
                    <button
                        className="btn btn-primary editProfile"
                        onClick={() => setEditPassword(true)}>
                        Edit Password
                    </button>
            }
        </div>
    )
}
