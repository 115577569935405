import React from 'react'

export default function InputEmail({ application, question, navTab, formValues, setFormValues }) {

    const handleInputChange = event => {
        const { id, value } = event.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [navTab]: {
                ...formValues[navTab],
                [id]: value
            }
        }));
    };

    return (
        <>
            <div className="col-md-4">
                <label htmlFor={question.id} className="form-label" style={{ color: "white" }}>{question.label}
                    {
                        question.required ? <span style={{ color: "red" }}> *</span> : null
                    }
                </label>
                <input type="email" className="form-control" id={question.id} required={question.required}
                    value={application?.[navTab]?.[question.id] || formValues[navTab]?.[question.id] || ''}
                    onChange={handleInputChange}
                    readOnly={application}
                />
            </div>
        </>
    )
}
