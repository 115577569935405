import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Home from './home'
import About from './about'
import Services from './services'
import ContactUs from './contact-us'
import ScrollToHashElement from '../ScrollToHashElement'
import BackToTop from './back-to-top'
import { useExternalFile } from 'useExternalFile'
import Pricing from './pricing'

export default function Index(props) {

    useExternalFile("/assets/js/main.js")
    let navigate = useNavigate()

    useEffect(() => {
        navigate('/')
    }, [])

    useEffect(() => {
        /**
            * Animation on scroll
        */
        window.AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false
        });
    }, [])

    return (
        <>
            <ScrollToHashElement />
            <Home />
            <main id="main">
                <About />
                <Services />
                <Pricing />
                <ContactUs />
            </main>
            <BackToTop />
        </>
    )
}
