import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './viewApplications.css'
import Spinner from 'Spinner';
import { GetAllApplications } from 'api/admin';
import UploadFile from './uploadFile'
import { ProfileContext, ApplicationContext, SubDomainContext } from 'context';
import Register from 'MyApplications/user'

export default function Index(props) {

    const { profileData } = useContext(ProfileContext)
    const { application: userApplication, setApplication: setUserApplication } = useContext(ApplicationContext)

    const navigate = useNavigate()

    const location = useLocation();
    const { applicationId } = useParams();
    const [isFetching, setIsFetching] = useState(false)
    var count = 0;

    useEffect(() => {
        if (!profileData) {
            navigate('/login')
        }
    }, [profileData, navigate])

    useEffect(() => {
        window.history.replaceState({}, document.title)
        // Set the background color when the component mounts
        document.body.style.backgroundColor = '#663800';

        // Cleanup function to unset the background color when the component unmounts
        return () => {
            document.body.style.backgroundColor = ''; // Reset to the default background color
        };
    }, []); // Empty dependency array to ensure the effect runs only on mount and unmount

    useEffect(() => {
        if (location.state && location.state.application) {
            setUserApplication(location.state.application)
        } else {
            if (count === 0) {
                count++;
                fetchApplication()
            }
        }
    }, [])

    const fetchApplication = async () => {
        if (!profileData) return;
        if (!isFetching) {
            setIsFetching(true)
            const result = await GetAllApplications({ applicationId }, null, null)
            // console.log(result)
            if (result?.status === 200 && result?.data && result.data.applications.length > 0) {
                setUserApplication(result.data.applications[0])
            }
        }
        setIsFetching(false)
    }

    return (
        userApplication ? <Application application={userApplication} /> : <Spinner />
    )
}


function Application({ application }) {

    const { subDomain } = useContext(SubDomainContext)

    return (
        <>
            <div className='container viewApplications'>
                <div className='row'>
                    {
                        subDomain === "admin" ? <Register /> :
                            <>
                                <div className='col-sm-8 viewEmployeeApplications' style={{ height: "80vh", overflowY: "auto" }}>
                                    <Register />
                                </div>
                                <div className='col-sm-4'>
                                    <UploadFile application={application} />
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}