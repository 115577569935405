import axios from "./axiosInstance";
import {
    sendVerificationEmailRoute, signUpUserRoute, signInUserRoute,
    updateUserProfileRoute, updateUserPasswordRoute, feedbackRoute,
    contactUsMessageRoute
} from "config";

export const SendVerificationEmail = async (userId, email) => {
    try {
        const response = await axios.get(sendVerificationEmailRoute, { params: { userId, email } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const SignUpUser = async (name, email, password) => {
    try {
        const response = await axios.post(signUpUserRoute, { name, email, password })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const LogInUser = async (email, password) => {
    try {
        const response = await axios.post(signInUserRoute, { email, password })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const UpdateUserProfile = async (name, mobileNumber) => {
    try {
        const response = await axios.post(updateUserProfileRoute, { name, mobileNumber })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const UpdateUserPassword = async (oldPassword, newPassword) => {
    try {
        const response = await axios.post(updateUserPasswordRoute, { oldPassword, newPassword })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const ApplicationFeedBack = async (from, subject, message, application_id) => {
    try {
        const response = await axios.post(feedbackRoute, { from, subject, message, application_id })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const ContactUsMessage = async (name, email, subject, message) => {
    try {
        const response = await axios.post(contactUsMessageRoute, { name, email, subject, message })
        return response.data
    } catch (err) {
        console.log(err);
    }
}