import axios from "./axiosInstance";
import { getUserApplicationRoute, createApplicationRoute } from "config";

export const GetUserApplication = async () => {
    try {
        if (!(axios.defaults.headers.common['x-access-token'])) return;
        const response = await axios.get(getUserApplicationRoute)
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const CreateApplication = async (personalDetails, workVisaStatus, education, employmentDetails,
    createResume, createCoverLetter, resumeData, coverLetterData, resumeImgData, didUserUploadResume, didUserUploadCoverLetter) => {
    try {
        const response = await axios.post(createApplicationRoute, {
            personalDetails, workVisaStatus, education, employmentDetails,
            createResume, createCoverLetter, resumeData, coverLetterData, resumeImgData,
            didUserUploadResume, didUserUploadCoverLetter
        })
        // console.log(response)
        return response.data
    } catch (err) {
        console.log(err);
    }
}
