import React, { useState } from 'react'

export default function InputText({ assignApplication }) {

    const [applicationId, setApplicationId] = useState('')

    return (
        <>
            <form>
                <div className="row mb-3">
                    <div>
                        <input type="text" className="form-control" id="applicationId"
                            style={{ border: "1px solid #9E9E9E" }}
                            value={applicationId}
                            onChange={(e) => setApplicationId(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="d-grid gap-2 col-6 mx-auto mt-4">
                    <button className="btn btn-primary" type="button"
                        onClick={() => assignApplication(applicationId)}
                    >Assign</button>
                </div>
            </form>
        </>
    )
}
