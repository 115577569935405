import axios from "./axiosInstance";
import {
    assignApplicationToEmployeeRoute, getAllApplicationsRoute, getApplicationsOptionsRoute, getAllEmployeesRoute,
    getAllUsersRoute, getAllUpdatesRoute, deleteEmployeeRoute, changeEmployeePasswordRoute,
    updateAdminPasswordRoute, signInAdminRoute, signUpEmployeeRoute
} from "config";

export const AssignApplicationToEmployee = async (employeeId, applicationId) => {
    try {
        const response = await axios.get(assignApplicationToEmployeeRoute, { params: { employeeId, applicationId } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const GetAllApplications = async (filters, page, limit) => {
    try {
        const response = await axios.get(getAllApplicationsRoute, { params: { filters, page, limit } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const GetApplicationOptions = async (page, limit) => {
    try {
        const response = await axios.get(getApplicationsOptionsRoute, { params: { page, limit } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const GetAllEmployees = async (filters, page, limit) => {
    try {
        const response = await axios.get(getAllEmployeesRoute, { params: { filters, page, limit } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const GetAllUsers = async (filters, page, limit) => {
    try {
        const response = await axios.get(getAllUsersRoute, { params: { filters, page, limit } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const GetAllUpdates = async (filters, page, limit) => {
    try {
        const response = await axios.get(getAllUpdatesRoute, { params: { filters, page, limit } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const DeleteEmployee = async (employeeId) => {
    try {
        const response = await axios.get(deleteEmployeeRoute, { params: { employeeId } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const ChangeEmployeePassword = async (employeeId) => {
    try {
        const response = await axios.get(changeEmployeePasswordRoute, { params: { employeeId } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const UpdateAdminPassword = async (oldPassword, newPassword) => {
    try {
        const response = await axios.post(updateAdminPasswordRoute, { oldPassword, newPassword })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const SignInAdmin = async (email, password) => {
    try {
        const response = await axios.post(signInAdminRoute, { email, password })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const SignUpEmployee = async (name, email, mobileNumber) => {
    try {
        const response = await axios.post(signUpEmployeeRoute, { name, email, mobileNumber })
        return response.data
    } catch (err) {
        console.log(err);
    }
}


