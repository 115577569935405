import React, { useState, createContext, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ProfileContext } from './index'
import { GetUserApplication } from 'api/application'

export const applicationContext = createContext();

export default function Application({ children }) {

    const { pathname } = useLocation();
    const { profileData, token } = useContext(ProfileContext)
    const [application, setApplication] = useState()
    const [fetchingApplications, setFetchingApplications] = useState(false)
 
    useEffect(() => {
        const fetchUserApplication = async () => {
            try {
                if (!profileData || !token) return;
                setFetchingApplications(true)
                const result = await GetUserApplication()
                if (result?.status === 200 && result?.application) {
                    setApplication(result.application)
                }
                setFetchingApplications(false)
            } catch (err) {
                console.log(err)
                setFetchingApplications(false)
            }
        }

        if (profileData && token && !application) {
            fetchUserApplication()
        }
    }, [pathname, profileData, token, application])

    useEffect(() => {
        if(!profileData){
            setApplication()
            setFetchingApplications(false)
        }
    }, [profileData])

    const providerItem = {
        application, setApplication,
        fetchingApplications, setFetchingApplications
    }

    return (
        <>
            <applicationContext.Provider value={providerItem}>
                {children}
            </applicationContext.Provider>
        </>
    )
}
