import axios from "./axiosInstance";
import {
    getEmployeeAssignedApplicationsRoute, createApplicationUpdateRoute,
    createUserFileRoute, updateEmployeeProfileRoute, updateEmployeePasswordRoute,
    updateEmployeeInitialPasswordRoute, signInEmployeeRoute
} from "config";

export const GetEmployeeAssignedApplications = async (filters, page, limit) => {
    try {
        const response = await axios.get(getEmployeeAssignedApplicationsRoute, { params: { filters, page, limit } })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const CreateApplicationUpdate = async (applicationId, subject, message) => {
    try {
        const response = await axios.post(createApplicationUpdateRoute, { applicationId, subject, message })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const CreateUserFile = async (applicantEmail, applicationId, fileName, fileData, fileCategory) => {
    try {
        const response = await axios.post(createUserFileRoute, { applicantEmail, applicationId, fileName, fileData, fileCategory })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const UpdateEmployeeProfile = async (name, mobileNumber) => {
    try {
        const response = await axios.post(updateEmployeeProfileRoute, { name, mobileNumber })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const UpdateEmployeePassword = async (oldPassword, newPassword, changeFirstPassword) => {
    try {
        const response = await axios.post(updateEmployeePasswordRoute, { oldPassword, newPassword, changeFirstPassword })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const UpdateEmployeeInitialPassword = async (employeeId, oldPassword, newPassword) => {
    try {
        const response = await axios.post(updateEmployeeInitialPasswordRoute, { employeeId, oldPassword, newPassword })
        return response.data
    } catch (err) {
        console.log(err);
    }
}

export const SignInEmployee = async (email, password) => {
    try {
        const response = await axios.post(signInEmployeeRoute, { email, password })
        return response.data
    } catch (err) {
        console.log(err);
    }
}
