import { useEffect } from 'react';

export const useExternalFile = (resourceUrl) => {

  useEffect(() => {
    const resourceType = resourceUrl.endsWith('.css') ? 'css' : 'script';

    const resourceElement = resourceType === 'css'
      ? document.createElement('link')
      : document.createElement('script');

    if (resourceType === 'css') {
      resourceElement.href = resourceUrl;
      resourceElement.rel = 'stylesheet';
      document.head.appendChild(resourceElement);
    } else {
      resourceElement.src = resourceUrl;
      resourceElement.async = true;
      document.body.appendChild(resourceElement);
    }

    return () => {
      if (resourceType === 'css') {
        document.head.removeChild(resourceElement);
      } else {
        document.body.removeChild(resourceElement);
      }
    };
  }, [resourceUrl]);

  return null;
};

