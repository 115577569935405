import React from 'react'

export default function ViewPDF({ pdfURL, showPDF, setShowPDF }) {

    return (
        <>
            {
                showPDF ?
                    <div id="viewPDF">
                        <div className='closePDF' onClick={() => setShowPDF(false)}>
                            <i className="bi bi-x-circle"></i>
                        </div>
                        <iframe
                            key={pdfURL}
                            src={pdfURL}
                            height="100%" width="100%"
                            title={pdfURL}
                        />
                    </div> : null
            }
        </>
    )
}
