import React from 'react'
import { RegionDropdown } from 'react-country-region-selector';

export default function InputState({ application, question, navTab, formValues, setFormValues, position }) {

    const handleInputChange = (id, value) => {
        setFormValues(prevValues => {
            if (navTab === 'education') {
                const existingValues = prevValues[navTab]?.[position] || {};
                return {
                    ...prevValues,
                    [navTab]: {
                        ...formValues[navTab],
                        [position]: {
                            ...existingValues,
                            [id]: value,
                        }
                    }
                };
            }
        });
    };

    return (
        <>
            <div className="col-md-4">
                <label htmlFor={question.id} className="form-label" style={{ color: "white" }}>{question.label}
                    {
                        question.required ? <span style={{ color: "red" }}> *</span> : null
                    }
                </label>
                <RegionDropdown
                    className="form-select" id={question.id} required={question.required}
                    country={application?.[navTab]?.[position]?.['country'] || formValues[navTab]?.[position]?.['country'] || ''}
                    value={application?.[navTab]?.[position]?.[question.id] || formValues[navTab]?.[position]?.[question.id] || ''}
                    onChange={(value) => handleInputChange(question.id, value)}
                    disabled={application ? true : false}
                />
            </div>
        </>
    )
}
