import React from 'react'

export default function InputDate({ application, question, navTab, formValues, setFormValues, position }) {

    const handleInputChange = event => {
        const { id, value } = event.target;

        setFormValues(prevValues => {
            if (navTab === 'education') {
                const existingValues = prevValues[navTab]?.[position] || {};
                return {
                    ...prevValues,
                    [navTab]: {
                        ...formValues[navTab],
                        [position]: {
                            ...existingValues,
                            [id]: value,
                        }
                    }
                };
            }
        });
    };

    return (
        <>
            <div className="col-md-4">
                <label htmlFor={question.id} className="form-label" style={{ color: "white" }}>{question.label}
                    {
                        question.required ? <span style={{ color: "red" }}> *</span> : null
                    }
                </label>
                <input type="date" className="form-control" id={question.id} required={question.required}
                    value={application?.[navTab]?.[position]?.[question.id] || formValues[navTab]?.[position]?.[question.id] || ''}
                    onChange={handleInputChange}
                    readOnly={application}
                />
            </div>
        </>
    )
}
