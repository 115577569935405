import { UpdatesContext } from 'context'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'

export default function SideBar() {

    const resetFormFilters = {
        applicationId: '',
        employeeId: ''
    }

    const { sideBarActive, setSideBarActive, filters, setFilters } = useContext(UpdatesContext)
    const [formFilters, setFormFilters] = useState(resetFormFilters)

    useEffect(() => {
        if (filters) {
            // Update formFilters based on filters for matching keys
            Object.keys(resetFormFilters).forEach(key => {
                if (filters.hasOwnProperty(key)) {
                    setFormFilters(prevFilters => ({
                        ...prevFilters,
                        [key]: filters[key]
                    }));
                }
            });
        }
    }, [filters])

    const handleFormFilter = async (e) => {
        const value = e.target.value.trim()
        setFormFilters(prev => ({
            ...prev,
            [e.target.name]: prev[e.target.name] === value ? undefined : value
        }))
    }

    const handleSearch = async () => {

        // Check if filters and formFilters have the same values for matching keys
        const areFiltersEqual = Object.keys(formFilters).every(key => {
            return filters.hasOwnProperty(key) && filters[key] === formFilters[key];
        });

        // console.log(areFiltersEqual)

        if (!areFiltersEqual) {
            setFilters(prev => ({
                ...prev,
                ...formFilters
            }))
        }
    }

    const handleClear = async () => {
        setFormFilters(resetFormFilters)
        setFilters(resetFormFilters)
    }

    return (
        <>
            <nav id="sidebar" className={`${sideBarActive ? 'active' : ''}`}>
                <div className="custom-menu">
                    <button type="button" id="sidebarCollapse" className="btn btn-primary" onClick={() => setSideBarActive(prev => !prev)}>
                        <i className="bi bi-list" />
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4 scrollBar filterQuestions">
                    <div className='row' style={{ marginRight: "35px" }}>
                        <h1 className='col-8'>Filters</h1>
                        <button className='btn btn-secondary col-4 clearBtn' onClick={handleClear}>clear</button>
                    </div>
                    <div className='row'>

                        <div id="applicationId">
                            <h6>Application Id</h6>
                            <input
                                type="search"
                                className='form-control'
                                name='applicationId'
                                value={formFilters.applicationId || ''}
                                onChange={handleFormFilter}
                            />
                            <br />
                        </div>

                        <div id="employeeId">
                            <h6>Employee Id</h6>
                            <input
                                type="search"
                                className='form-control'
                                name='employeeId'
                                value={formFilters.employeeId || ''}
                                onChange={handleFormFilter}
                            />
                            <br />
                        </div>

                        <div id="From">
                            <h6 style={{ paddingBottom: "10px" }}>From</h6>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="from" id="inlineRadio1" value="EMPLOYEE"
                                    checked={formFilters.from === "EMPLOYEE"}
                                    onClick={
                                        (e) => {
                                            if (formFilters[e.target.name] === "EMPLOYEE") {
                                                handleFormFilter(e)
                                            }
                                        }
                                    }
                                    onChange={handleFormFilter}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">EMPLOYEE</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="from" id="inlineRadio2" value="USER"
                                    checked={formFilters.from === "USER"}
                                    onClick={
                                        (e) => {
                                            if (formFilters[e.target.name] === "USER") {
                                                handleFormFilter(e)
                                            }
                                        }
                                    }
                                    onChange={handleFormFilter}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">USER</label>
                            </div>
                        </div>

                    </div>
                    <br />
                    <button className='btn' style={{ background: "#d2691e", color: "white", width: "100%" }}
                        onClick={handleSearch}
                    >
                        Search
                    </button>
                    <br /><br /><br /><br />

                </div>
            </nav >
        </>
    )
}
