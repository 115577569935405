import React, { useState } from 'react'
import AlertBox from 'AlertBox'
import Spinner from 'Spinner'
import "./modal.css"
import NavTab from './navTab'
import InputSelect from './InputSelect'
import InputText from './InputText'
import { AssignApplicationToEmployee } from 'api/admin'

export default function Index({ employeeId }) {

    const [showAlert, setShowAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const [navTab, setNavTab] = useState('select')

    const assignApplication = async (applicationId) => {
        try {
            if (!employeeId || !applicationId) return;
            setLoading(true)
            const result = await AssignApplicationToEmployee(employeeId, applicationId)
            // console.log(result)
            if (result) {
                setShowAlert(result.message)
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" id={"assignApplication"} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Assign application</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <NavTab navTab={navTab} setNavTab={setNavTab} />
                            {
                                navTab === "select" ?
                                    <InputSelect assignApplication={assignApplication} /> :
                                    <InputText assignApplication={assignApplication} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AlertBox
                showAlert={showAlert}
                setShowAlert={setShowAlert}
            />
            {
                loading ? <Spinner /> : null
            }
        </>
    )
}
