import { SubDomainContext, AdminProfileContext } from 'context'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'

export default function SideBar() {

    const resetFormFilters = {
        applicationId: '',
        email: '',
        createResume: '',
        createCoverLetter: '',
        assignedEmployeeId: '',
    }

    const { subDomain } = useContext(SubDomainContext)

    if (subDomain === "admin") { resetFormFilters.employeeId = "" }

    const { sideBarActive, setSideBarActive, filters, setFilters } = useContext(AdminProfileContext)
    const [formFilters, setFormFilters] = useState(resetFormFilters)

    useEffect(() => {
        if (filters) {
            // Update formFilters based on filters for matching keys
            Object.keys(resetFormFilters).forEach(key => {
                if (filters.hasOwnProperty(key)) {
                    setFormFilters(prevFilters => ({
                        ...prevFilters,
                        [key]: filters[key]
                    }));
                }
            });
        }
    }, [filters])

    const handleFormFilter = async (e) => {
        const value = e.target.value.trim()
        setFormFilters(prev => ({
            ...prev,
            [e.target.name]: prev[e.target.name] === value ? undefined : value
        }))
    }

    const handleSearch = async () => {
        // Check if filters and formFilters have the same values for matching keys
        const areFiltersEqual = Object.keys(formFilters).every(key => {
            return filters.hasOwnProperty(key) && filters[key] === formFilters[key];
        });

        // console.log(areFiltersEqual)

        if (!areFiltersEqual) {
            setFilters(prev => ({
                ...prev,
                ...formFilters
            }))
        }
    }

    const handleClear = async () => {
        setFormFilters(resetFormFilters)
        setFilters(prev => ({
            ...prev,
            ...resetFormFilters
        }))
    }

    return (
        <>
            <nav id="sidebar" className={`${sideBarActive ? 'active' : ''}`}>
                <div className="custom-menu">
                    <button type="button" id="sidebarCollapse" className="btn btn-primary" onClick={() => setSideBarActive(prev => !prev)}>
                        <i className="bi bi-list" />
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4 scrollBar filterQuestions">
                    <div className='row' style={{ marginRight: "35px" }}>
                        <h1 className='col-8'>Filters</h1>
                        <button className='btn btn-secondary col-4 clearBtn' onClick={handleClear}>clear</button>
                    </div>
                    <div className='row'>
                        {
                            subDomain === "admin" ?
                                <>
                                    <div id="applicationId">
                                        <h6>Application Id</h6>
                                        <input
                                            type="search"
                                            className='form-control'
                                            name='applicationId'
                                            value={formFilters.applicationId || ''}
                                            onChange={handleFormFilter}
                                        />
                                        <br />
                                    </div>
                                    <div id="employeeId">
                                        <h6>Employee Id</h6>
                                        <input
                                            type="search"
                                            className='form-control'
                                            name='employeeId'
                                            value={formFilters.employeeId || ''}
                                            onChange={handleFormFilter}
                                        />
                                        <br />
                                    </div>
                                </> : null
                        }
                        <div id="userEmail">
                            <h6>User Email</h6>
                            <input
                                type="search"
                                className='form-control'
                                name='email'
                                value={formFilters.email || ''}
                                onChange={handleFormFilter}
                            />
                            <br />
                        </div>
                        {/* <div id="jobRole">
                            <h6>Job Role</h6>
                            <input
                                type="search"
                                className='form-control'
                                name='jobRole'
                                value={formFilters.jobRole || ''}
                                onChange={handleFormFilter}
                            />
                            <br />
                        </div> */}
                        <div id="createResume">
                            <h6 style={{ paddingBottom: "10px" }}>Create Resume</h6>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="createResume" id="inlineRadio1" value="true"
                                    checked={formFilters.createResume === "true"}
                                    onClick={
                                        (e) => {
                                            if (formFilters[e.target.name] === "true") {
                                                handleFormFilter(e)
                                            }
                                        }
                                    }
                                    onChange={handleFormFilter}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">True</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="createResume" id="inlineRadio2" value="false"
                                    checked={formFilters.createResume === "false"}
                                    onClick={
                                        (e) => {
                                            if (formFilters[e.target.name] === "false") {
                                                handleFormFilter(e)
                                            }
                                        }
                                    }
                                    onChange={handleFormFilter}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">False</label>
                            </div>
                        </div>
                        <div id="createCoverLetter" style={{ marginTop: "10px" }}>
                            <h6 style={{ paddingBottom: "10px" }}>Create CoverLetter</h6>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="createCoverLetter" id="inlineRadio3" value="true"
                                    checked={formFilters.createCoverLetter === "true"}
                                    onClick={
                                        (e) => {
                                            if (formFilters[e.target.name] === "true") {
                                                handleFormFilter(e)
                                            }
                                        }
                                    }
                                    onChange={handleFormFilter}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">True</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="createCoverLetter" id="inlineRadio4" value="false"
                                    checked={formFilters.createCoverLetter === "false"}
                                    onClick={
                                        (e) => {
                                            if (formFilters[e.target.name] === "false") {
                                                handleFormFilter(e)
                                            }
                                        }
                                    }
                                    onChange={handleFormFilter}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio4">False</label>
                            </div>
                        </div>
                        {
                            subDomain === "admin" ?
                                <div id="employeeAssignedApplications" style={{ marginTop: "10px" }}>
                                    <h6 style={{ paddingBottom: "10px" }}>Employee Assigned Clients</h6>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="assignedEmployeeId" id="inlineRadio5" value="true"
                                            checked={formFilters.assignedEmployeeId === "true"}
                                            onClick={
                                                (e) => {
                                                    if (formFilters[e.target.name] === "true") {
                                                        handleFormFilter(e)
                                                    }
                                                }
                                            }
                                            onChange={handleFormFilter}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio5">True</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="assignedEmployeeId" id="inlineRadio6" value="false"
                                            checked={formFilters.assignedEmployeeId === "false"}
                                            onClick={
                                                (e) => {
                                                    if (formFilters[e.target.name] === "false") {
                                                        handleFormFilter(e)
                                                    }
                                                }
                                            }
                                            onChange={handleFormFilter}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio6">False</label>
                                    </div>
                                </div> : null
                        }

                    </div>
                    <br />
                    <button className='btn' style={{ background: "#d2691e", color: "white", width: "100%" }}
                        onClick={handleSearch}
                    >
                        Search
                    </button>
                    <br /><br /><br /><br />
                    {/* <ul className="list-unstyled components mb-5">
                        <li className="active">
                            <a href="#"><span className="fa fa-home mr-3"></span> Home</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-user mr-3"></span> About</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-briefcase mr-3"></span> Works</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-sticky-note mr-3"></span> Blog</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-suitcase mr-3"></span> Gallery</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-cogs mr-3"></span> Services</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-paper-plane mr-3"></span> Contacts</a>
                        </li>
                    </ul>
                    <ul className="list-unstyled components mb-5">
                        <li className="active">
                            <a href="#"><span className="fa fa-home mr-3"></span> Home</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-user mr-3"></span> About</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-briefcase mr-3"></span> Works</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-sticky-note mr-3"></span> Blog</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-suitcase mr-3"></span> Gallery</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-cogs mr-3"></span> Services</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-paper-plane mr-3"></span> Contacts</a>
                        </li>
                    </ul>
                    <ul className="list-unstyled components mb-5">
                        <li className="active">
                            <a href="#"><span className="fa fa-home mr-3"></span> Home</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-user mr-3"></span> About</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-briefcase mr-3"></span> Works</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-sticky-note mr-3"></span> Blog</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-suitcase mr-3"></span> Gallery</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-cogs mr-3"></span> Services</a>
                        </li>
                        <li>
                            <a href="#"><span className="fa fa-paper-plane mr-3"></span> Contacts</a>
                        </li>
                    </ul>

                    <div className="mb-5">
                        <h3 className="h6 mb-3">Subscribe for newsletter</h3>
                        <form action="#" className="subscribe-form">
                            <div className="form-group d-flex">
                                <div className="icon"><span className="icon-paper-plane"></span></div>
                                <input type="text" className="form-control" placeholder="Enter Email Address" />
                            </div>
                        </form>
                    </div>

                    <div className="footer">
                        <p>
                            Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a>
                        </p>
                    </div> */}

                </div>
            </nav>
        </>
    )
}
